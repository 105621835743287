import * as Cookies from 'js-cookie'
import pako from 'pako'
import router from '@/router'
import sidebarJson from '@/components/globals/sidebar/sidebar.json'
import dashboardJson from '@/components/globals/sidebar/dashboard.json'
import pandoraJson from '@/components/globals/sidebar/pandora.json'
import orionJson from '@/components/globals/sidebar/orion.json'
import heimdalJson from '@/components/globals/sidebar/heimdal.json'
import { firstPage } from './helper'
// import sidebarJson from "@/components/globals/sidebar/"

export const setCookie = (cookie) => {
  // Compress cookie with gzip
  const compressedData = pako.gzip(cookie, { to: 'string' })

  // Encode compressed data to base64
  const encodedData = btoa(String.fromCharCode.apply(null, compressedData))

  // Set cookie with compressed and encoded data
  Cookies.set('superindo-token', encodedData, { path: '/' })
  return {
    token: Cookies.get('superindo-token')
  }
}

export const decodeAndDecompressCookie = (cookieName) => {
  try {
    // Retrieve encoded data from cookie
    const encodedData = Cookies.get(cookieName)
    if (!encodedData) {
      console.error(`Cookie ${cookieName} not found.`)
      return
    }

    // Decode base64 encoded data
    const decodedData = atob(encodedData)

    // Convert decoded string back to Uint8Array
    const byteArray = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i)
    }

    // Decompress gzip compressed data using pako
    const decompressedData = pako.inflate(byteArray, { to: 'string' })

    return decompressedData
  } catch (error) {
    console.error('Error decoding and decompressing cookie:', error)
    return null
  }
}

export const setCookieProfile = (cookie) => {
  Cookies.set('superindo-profile', cookie, { path: '/' })
  return {
    profile: Cookies.get('superindo-profile')
  }
}

export const getUserToken = () => {
  return decodeAndDecompressCookie('superindo-token')
}

export const getUserProfile = () => {
  return JSON.parse(Cookies.get('superindo-profile'))
}

export const isLogin = () => {
  const token = Cookies.get('superindo-token')
  const profile = Cookies.get('superindo-profile')
  if (token && profile) {
    return true
  } 
  return false
}

export const isAuthorizePage = (url) => {
  if(getUserProfile()?.role == 'Super Admin') {
    return true
  }
  if (!localStorage.getItem('all_menu')) {
    return true
  }
  const roles = JSON.parse(localStorage.getItem('all_menu')).some(role=> role.to == url)
  return roles
} 

export const logoutUser = () => {
  Cookies.remove('superindo-token', { path: '/' })
  Cookies.remove('superindo-profile', { path: '/' })
  localStorage.removeItem('role_managements')
  localStorage.removeItem('all_menu')
  router.go(0)
}

export const getDataRole = () => {
  return JSON.parse(localStorage.getItem('role_managements'))
}

export const getDefaultRedirect = (type) => {
  const checkSidebarJson = (type) => {
    switch (type) {
    case 'ssosis': return dashboardJson
    case 'cms': return sidebarJson
    case 'pandora': return pandoraJson
    case 'orion': return orionJson
    case 'heimdal': return heimdalJson
    default: return []
    }
  }

  if(isLogin()) {
    if(getUserProfile()?.role != 'Super Admin') {
      const dataRole = getDataRole()?.filter(item => {
        if (item.view && item.type == type) {
          return item
        }
      })
      const newSidebar = checkSidebarJson(type).map(item => item.child.length < 1 ? [item.parent].filter(i => dataRole.map(role => role.name).includes(i.name)) : item.child.filter(i => dataRole.map(role => role.name).includes(i.name))).flat()
      return newSidebar[0]?.to
    } else {
      return firstPage[type]
    }
  }
}
