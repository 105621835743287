import { apiPostAuth, apiPutAuth, apiGetAuth, apiDeleteAuth } from '@/utils/api'
import { API_CAMPAIGN, API_USER_MANAGEMENT, API_DIGITAL_STAMP } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    detail_user: { data: []},
    offers: { data: [] },
    push_messages: { data: [] },
    banners: { data: [] },
    campaigns: { data: [] },
    rewards: { data: [] },
    detail_campaign: { data: {}},
    meta_filter: {},
    meta_filter2: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_OFFER (state, payload) {
    state.offers = payload
  },
  SET_PUSH_MESSAGE (state, payload) {
    state.push_messages = payload
  },
  SET_BANNER (state, payload) {
    state.banners = payload
  },
  SET_CAMPAIGN (state, payload) {
    state.campaigns = payload
  },
  SET_REWARDS (state, payload) {
    state.rewards = payload
  },
  SET_DETAIL_CAMPAIGN (state, payload) {
    state.detail_campaign = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER2 (state, payload) {
    state.meta_filter2 = payload
  }
}

const actions = {
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListOffer (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_OFFER, payload)
      context.commit('SET_OFFER', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListPushMessage(context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_PUSH_MESSAGE, payload)
      context.commit('SET_PUSH_MESSAGE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListBanner(context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_BANNER, payload)
      context.commit('SET_BANNER', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListCampaign (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_CAMPAIGN, payload)
      context.commit('SET_CAMPAIGN', response.data.data)
      context.commit('SET_META_FILTER2', payload)
      router.push({ name: 'Campaigns', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListReward(context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_STAMP.REWARD, payload)
      context.commit('SET_REWARDS', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailCampaign (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.DETAIL_CAMPAIGN(payload))
      context.commit('SET_DETAIL_CAMPAIGN', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createCampaign (context, payload) {
    try {
      const response = await apiPostAuth(API_CAMPAIGN.CREATE_CAMPAIGN, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateCampaign (context, payload) {
    try {
      const response = await apiPutAuth(API_CAMPAIGN.UPDATE_CAMPAIGN(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteCampaign (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CAMPAIGN.DELETE_CAMPAIGN(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  detail_user (state) {
    return state.detail_user
  },
  list_offer (state) {
    return state.offers
  },
  list_push_message (state) {
    return state.push_messages
  },
  list_banner (state) {
    return state.banners
  },
  list_campaign (state) {
    return state.campaigns
  },
  list_rewards (state) {
    return state.rewards
  },
  detail_campaign (state) {
    return state.detail_campaign
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
