import {apiGetAuth, apiPostAuth} from '@/utils/api'
import { API_PANDORA_DASHBOARD_ANALYTIC } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_customers: { data: {}, loading: false },
    device_type: { data: [], loading: false },
    device_trend: { data: [], loading: false },
    gender_trend: { data: [], loading: false},
    demographic: { data: []}, loading: false,
    ave: { data: [], loading: false},
    dnr: { data: [], loading: false},
    count_new_online: { data: [], loading: false},
    count_exist_online: { data: [], loading: false},
    count_new_offline: { data: [], loading: false},
    count_exist_offline: { data: [], loading: false},
    count_table: { data: [], loading: false},
    new_user_online:{ data: [], loading: false},
    existing_user_online: {data: [], loading: false},
    new_user_offline: {data: [], loading: false},
    existing_user_offline: {data: [], loading: false},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUNTER_CUSTOMER (state, payload) {
    state.counter_customers = payload
  },
  SET_CUSTOMER_DEVICE_TYPE (state, payload) {
    state.device_type = payload
  },
  SET_CUSTOMER_DEVICE_TREND (state, payload) {
    state.device_trend = payload
  },
  SET_CUSTOMER_GENDER_TREND (state, payload) {
    state.gender_trend = payload
  },
  SET_CUSTOMER_DEMOGRAPHIC(state, payload) {
    state.demographic = payload
  },
  SET_CUSTOMER_AVE(state, payload) {
    state.ave = payload
  },
  SET_CUSTOMER_DNR(state, payload) {
    state.dnr = payload
  },
  SET_CUSTOMER_COUNT_NEW_ONLINE(state, payload) {
    state.count_new_online = payload
  },
  SET_CUSTOMER_COUNT_EXIST_ONLINE(state, payload) {
    state.count_exist_online = payload
  },
  SET_CUSTOMER_COUNT_NEW_OFFLINE(state, payload) {
    state.count_new_offline = payload
  },
  SET_CUSTOMER_COUNT_EXIST_OFFLINE(state, payload) {
    state.count_exist_offline = payload
  },
  SET_CUSTOMER_COUNT_TABLE(state, payload) {
    state.count_table = payload
  },
  SET_CUSTOMER_NEW_USER_ONLINE(state, payload) {
    state.new_user_online = payload
  },
  SET_CUSTOMER_EXISTING_USER_ONLINE(state, payload) {
    state.existing_user_online = payload
  },
  SET_CUSTOMER_NEW_USER_OFFLINE(state, payload) {
    state.new_user_offline = payload
  },
  SET_CUSTOMER_EXISTING_USER_OFFLINE(state, payload) {
    state.existing_user_offline = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getCounterCustomer (context, payload) {
    try {
      context.commit('SET_COUNTER_CUSTOMER', {data: {}, loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT, payload)
      context.commit('SET_COUNTER_CUSTOMER', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_COUNTER_CUSTOMER', {data: {}, loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerDeviceType (context, payload) {
    try {
      context.commit('SET_CUSTOMER_DEVICE_TYPE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_TYPE, payload)
      context.commit('SET_CUSTOMER_DEVICE_TYPE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_DEVICE_TYPE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerDeviceTrend (context, payload) {
    try {
      context.commit('SET_CUSTOMER_DEVICE_TREND', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_TREND, payload)
      context.commit('SET_CUSTOMER_DEVICE_TREND', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_DEVICE_TREND', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerGenderTrend (context, payload) {
    try {
      context.commit('SET_CUSTOMER_GENDER_TREND', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_GENDER, payload)
      context.commit('SET_CUSTOMER_GENDER_TREND', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_GENDER_TREND', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerDemographic (context, payload) {
    try {
      context.commit('SET_CUSTOMER_DEMOGRAPHIC', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_DEMOGRAPHIC, payload)
      context.commit('SET_CUSTOMER_DEMOGRAPHIC', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_DEMOGRAPHIC', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerAve (context, payload) {
    try {
      context.commit('SET_CUSTOMER_AVE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_AVE, payload)
      context.commit('SET_CUSTOMER_AVE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_AVE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerDnr (context, payload) {
    try {
      context.commit('SET_CUSTOMER_DNR', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_DNR, payload)
      context.commit('SET_CUSTOMER_DNR', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_DNR', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerCounter (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_COUNT_SUMMARY(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerGenderTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_GENDER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDeviceType (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_TYPE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDeviceTrend (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_TREND(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDemographic (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_DEMOGRAPHIC(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerDnr (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_DNR(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerAve (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_AVE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingUniqueTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_UNIQUE_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingSales (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_SALES(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCounterCustomer (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_COUNT_SUMMARY, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerDnr(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_DNR, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerAve(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_AVE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerDemographic(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_DEMOGRAPHIC, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerType(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_TYPE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerTrend(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_TREND, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerGender(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_GENDER, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCustomerCountNewOnline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_COUNT_NEW_ONLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT_NEW_ONLINE, payload)
      context.commit('SET_CUSTOMER_COUNT_NEW_ONLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_COUNT_NEW_ONLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerCountExistOnline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_COUNT_EXIST_ONLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT_EXIST_ONLINE, payload)
      context.commit('SET_CUSTOMER_COUNT_EXIST_ONLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_COUNT_EXIST_ONLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerCountNewOffline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_COUNT_NEW_OFFLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT_NEW_OFFLINE, payload)
      context.commit('SET_CUSTOMER_COUNT_NEW_OFFLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_COUNT_NEW_OFFLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerCountExistOffline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_COUNT_EXIST_OFFLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT_EXIST_OFFLINE, payload)
      context.commit('SET_CUSTOMER_COUNT_EXIST_OFFLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_COUNT_EXIST_OFFLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerCountTable (context, payload) {
    try {
      context.commit('SET_CUSTOMER_COUNT_TABLE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_COUNT_SUMMARY, payload)
      context.commit('SET_CUSTOMER_COUNT_TABLE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_COUNT_TABLE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerNewUserOnline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_NEW_USER_ONLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_NEW_USER_ONLINE, payload)
      context.commit('SET_CUSTOMER_NEW_USER_ONLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_NEW_USER_ONLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerExistingUserOnline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_EXISTING_USER_ONLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_EXIST_USER_ONLINE, payload)
      context.commit('SET_CUSTOMER_EXISTING_USER_ONLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_EXISTING_USER_ONLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerNewUserOffline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_NEW_USER_OFFLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_NEW_USER_OFFLINE, payload)
      context.commit('SET_CUSTOMER_NEW_USER_OFFLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_NEW_USER_OFFLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getCustomerExistingUserOffline (context, payload) {
    try {
      context.commit('SET_CUSTOMER_EXISTING_USER_OFFLINE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.CUSTOMER_EXIST_USER_OFFLINE, payload)
      context.commit('SET_CUSTOMER_EXISTING_USER_OFFLINE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CUSTOMER_EXISTING_USER_OFFLINE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerNewUserOnline (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_NEW_USER_ONLINE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerNewUserOnline(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_NEW_USER_ONLINE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerExistingUserOnline (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_EXIST_USER_ONLINE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerExistingUserOnline(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_EXIST_USER_ONLINE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerNewUserOffline (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_NEW_USER_OFFLINE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerNewUserOffline(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_NEW_USER_OFFLINE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCustomerExistingUserOffline (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_CUSTOMER_EXIST_USER_OFFLINE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareCustomerExistingUserOffline(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_CUSTOMER_EXIST_USER_OFFLINE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  counter_customers (state) {
    return state.counter_customers
  },
  device_trend (state) {
    return state.device_trend
  },
  device_type (state) {
    return state.device_type
  },
  demographic (state) {
    return state.demographic
  },
  ave (state) {
    return state.ave
  },
  dnr (state) {
    return state.dnr
  },
  gender_trend (state) {
    return state.gender_trend
  },
  count_new_online (state) {
    return state.count_new_online
  },
  count_exist_online (state) {
    return state.count_exist_online
  },
  count_new_offline (state) {
    return state.count_new_offline
  },
  count_exist_offline (state) {
    return state.count_exist_offline
  },
  count_table (state) {
    return state.count_table
  },
  new_user_online (state) {
    return state.new_user_online
  },
  existing_user_online (state) {
    return state.existing_user_online
  },
  new_user_offline (state) {
    return state.new_user_offline
  },
  existing_user_offline (state) {
    return state.existing_user_offline
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
