import { API_CONSUMER } from "@/utils/api-url"
import { apiGetAuth } from "@/utils/api"
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    login_attempts: {
      isLoading: false,
      data: false
    },
    login_attempt_detail: {
      isLoading: false,
      data: false
    },
    meta_filter: {},
    data_modal_add_edit: {}
  }
}

const mutations = {
  SET_LOGIN_ATTEMPTS (state, payload) {
    state.login_attempts = payload
  },
  SET_LOGIN_ATTEMPT_DETAIL (state, payload) {
    state.login_attempt_detail = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  }
}

const actions = {
  
  async getListLoginAttempt (context, payload) {
    try {
      context.commit('SET_LOGIN_ATTEMPTS', {isLoading: true})
      const response = await apiGetAuth(API_CONSUMER.LOGIN_ATTEMP, payload)
      context.commit('SET_LOGIN_ATTEMPTS', {data: response.data.data, isLoading: false})
    } catch (error) {
      context.commit('SET_LOGIN_ATTEMPTS', {data: false, isLoading: false})
      notificationDanger(error)
    }
  },
  async getDetailLoginAttempt (context, payload) {
    try {
      context.commit('SET_LOGIN_ATTEMPT_DETAIL', {isLoading: true})
      const response = await apiGetAuth(API_CONSUMER.LOGIN_ATTEMP_DETAIL, payload)
      context.commit('SET_LOGIN_ATTEMPT_DETAIL', {data: response.data.data, isLoading: false})
      router.replace({ name: 'LoginAttempt', query: payload })
    } catch (error) {
      context.commit('SET_LOGIN_ATTEMPT_DETAIL', {data: false, isLoading: false})
      notificationDanger(error)
    }
  },
  async exportLoginAttempt (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LOGIN_ATTEMP_CSV, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportLoginAttemptDetail (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LOGIN_ATTEMP_CSV_DETAIL, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_login_attempt (state) {
    return state.login_attempts
  },
  detail_login_attempt (state) {
    return state.login_attempt_detail
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
