import { apiGetAuth, apiPostAuth, apiPutAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api'
import { API_TOP_UP_BILLS } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'
import { exportCustomHeaderAndResponseType } from '@/utils/helper'
import { dateGetOnlyFullTime, dateMonthString } from '@/utils/formater'

const state = () => {
  return {
    order_management_digital: { 
      isLoading: false,
      data: []
    },
    banner_digital_product: { 
      isLoading: false,
      data: []
    },
    detail_order_management_digital: {},
    detail_banner_digital_product: {
      isLoading: false,
      data: null
    },
    cta_setting: {
      isLoading: false,
      data: {}
    },
    bulk_upload: {
      isLoading: false,
      data: {}
    },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_ORDER_MANAGEMENT_DIGITAL (state, payload) {
    state.order_management_digital = {...payload}
  },
  SET_BANNER_DIGITAL_PRODUCT (state, payload) {
    state.banner_digital_product = {...payload}
  },
  SET_DETAIL_ORDER_MANAGEMENT_DIGITAL (state, payload) {
    state.detail_order_management_digital = {...payload}
  },
  SET_DETAIL_BANNER_DIGITAL_PRODUCT (state, payload) {
    state.detail_banner_digital_product = {...payload}
  },
  SET_CTA_SETTING (state, payload) {
    state.cta_setting = {...payload}
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_BULK_UPLOAD (state, payload) {
    state.bulk_upload = {...payload}
  }
}

const actions = {
  async getListOrderManagementDigital(context, payload) {
    context.commit('SET_ORDER_MANAGEMENT_DIGITAL', {isLoading: true, data: []})
    try {
      
      const response = await apiGetAuth(API_TOP_UP_BILLS.LIST_ORDER_MANAGEMENT_DIGITAL, payload)
      context.commit('SET_ORDER_MANAGEMENT_DIGITAL', {isLoading: false, data: response.data.data})
    } catch (error) {
      context.commit('SET_ORDER_MANAGEMENT_DIGITAL', {isLoading: false, data: []})
      notificationDanger(error)
    }
  },
  async getDetailOrderManagementDigital(context, payload) {
    context.commit('SET_DETAIL_ORDER_MANAGEMENT_DIGITAL', {isLoading: true, data: {}})
    try {
      
      const response = await apiGetAuth(API_TOP_UP_BILLS.DETAIL_ORDER_MANAGEMENT_DIGITAL(payload))
      context.commit('SET_DETAIL_ORDER_MANAGEMENT_DIGITAL', {isLoading: false, data: response.data.data})
    } catch (error) {
      context.commit('SET_DETAIL_ORDER_MANAGEMENT_DIGITAL', {isLoading: false, data: {}})
      notificationDanger(error)
    }
  },
  async getListBannerDigitalProduct(context, payload) {
    context.commit('SET_BANNER_DIGITAL_PRODUCT', {isLoading: true, data: []})
    try {
      const response = await apiGetAuth(API_TOP_UP_BILLS.LIST_DIGITAL_BANNER, payload)
      context.commit('SET_BANNER_DIGITAL_PRODUCT', {isLoading: false, data: response.data.data})
    } catch (error) {
      context.commit('SET_BANNER_DIGITAL_PRODUCT', {isLoading: false, data: []})
      notificationDanger(error)
    }
  },
  async getDetailBannerDigitalProduct(context, payload) {
    context.commit('SET_DETAIL_BANNER_DIGITAL_PRODUCT', {isLoading: true, data: {}})
    try {
      const response = await apiGetAuth(API_TOP_UP_BILLS.DETAIL_DIGITAL_BANNER(payload))
      context.commit('SET_DETAIL_BANNER_DIGITAL_PRODUCT', {isLoading: false, data: response.data.data})
    } catch (error) {
      context.commit('SET_DETAIL_BANNER_DIGITAL_PRODUCT', {isLoading: false, data: {}})
      notificationDanger(error)
    }
  },
  async createDigitalBanner(context, payload) {
    try {
      const response = await apiPostAuth(API_TOP_UP_BILLS.CREATE_DIGITAL_BANNER, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateBannerDigitalProduct(context, payload) {
    try {
      const response = await apiPutAuth(API_TOP_UP_BILLS.UPDATE_DIGITAL_BANNER(payload.id), payload.body)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updatePositionDigitalBanner(context, payload) {
    try {
      const response = await apiPutAuth(API_TOP_UP_BILLS.UPDATE_POSITION_DIGITAL_BANNER, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCTASetting(context) {
    context.commit('SET_CTA_SETTING', {isLoading: true, data: {}})
    try {
      const response = await apiGetAuth(API_TOP_UP_BILLS.GET_CTA_SETTING)
      context.commit('SET_CTA_SETTING', {isLoading: false, data: response.data})
    } catch (error) {
      context.commit('SET_CTA_SETTING', {isLoading: false, data: {}})
      notificationDanger(error)
    }
  },
  async updateCTASetting(context, payload) {
    try {
      const response = await apiPutAuth(API_TOP_UP_BILLS.UPDATE_CTA_SETTING, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportOrderDigitalProduct (context, payload) {
    try {
      const response = await apiGetAuthWithCustomHeadersResponseType(API_TOP_UP_BILLS.EXPORT_ORDER_MANAGEMENT_DIGITAL, payload)
      
      exportCustomHeaderAndResponseType(response.data, `Top_Up_&_Bills_Order_Management_${dateMonthString(new Date(), 'no-space')}_${dateGetOnlyFullTime(new Date())}.xlsx`)
    } catch (error){
      notificationDanger(error)
    }
  },
  async retryOrderDigitalProduct (context, payload) {
    try {
      const response = await apiPostAuth(API_TOP_UP_BILLS.RETRY_ORDER_MANAGEMENT_DIGITAL(payload))
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async bulkUploadOrderManagementDigital (context, payload) {
    context.commit('SET_BULK_UPLOAD', {isLoading: true, data: []})
    try {
      const response = await apiPostAuth(API_TOP_UP_BILLS.BULK_ORDER_MANAGEMENT_DIGITAL, payload)
      context.commit('SET_BULK_UPLOAD', {isLoading: false, data: response.data})
    } catch (error){
      context.commidt('SET_BULK_UPLOAD', {isLoading: false, data: []})
      notificationDanger(error)
    }
  },
  async bulkUpdateOrderManagementDigital (context, payload) {
    try {
      const response = await apiPostAuth(API_TOP_UP_BILLS.BULK_UPDATE_ORDER_MANAGEMENT_DIGITAL, payload)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  cta_setting (state) {
    return state.cta_setting
  },
  list_order_management_digital (state) {
    return state.order_management_digital
  },
  list_banner_digital_product (state) {
    return state.banner_digital_product
  },
  detail_order_management_digital (state) {
    return state.detail_order_management_digital
  },
  detail_banner_digital_product (state) {
    return state.detail_banner_digital_product
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  list_bulk_uplaod (state) {
    return state.bulk_upload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
