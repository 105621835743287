import { apiGetAuth } from '@/utils/api'
import { API_PANDORA_CUSTOMER_ANALYTICS } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    ipc_pc: {data: []},
    apc_pc: {data: []},
    qpc_pc: {data: []},
    ipc_pt: {data: []},
    apc_pt: {data: []},
    qpc_pt: {data: []},
    export_data: {data: []},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_APC_PC (state, payload) {
    state.apc_pc = payload
  },
  SET_IPC_PC (state, payload) {
    state.ipc_pc = payload
  },
  SET_QPC_PC (state, payload) {
    state.qpc_pc = payload
  },
  SET_APC_PT (state, payload) {
    state.apc_pt = payload
  },
  SET_IPC_PT (state, payload) {
    state.ipc_pt = payload
  },
  SET_QPC_PT (state, payload) {
    state.qpc_pt = payload
  },
  SET_EXPORT_DATA (state, payload) {
    state.export_data = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
} 

const actions = {
  async getAllData (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_CUSTOMER_ANALYTICS.CUSTOMER_ANALYTICS, payload)
      context.commit('SET_' + payload.type_chart, response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getExportData(context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_CUSTOMER_ANALYTICS.EXPORT_CUSTOMER_ANALYTICS(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  get_ipc_pc(state){
    return state.ipc_pc
  },
  get_apc_pc(state){
    return state.apc_pc
  },
  get_qpc_pc(state){
    return state.qpc_pc
  },
  get_ipc_pt(state){
    return state.ipc_pt
  },
  get_apc_pt(state){
    return state.apc_pt
  },
  get_qpc_pt(state){
    return state.qpc_pt
  },
  get_export_data(state){
    return state.export_data
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
