
import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { notificationDanger } from '@/utils/notification'
import { API_CHAT_BOT } from '@/utils/api-url'

const state = () => {
  return {
    chatbot: { data: [] },
    chatbot_detail: { data: [] },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      targetModalTag: false,
      data: [],
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_CHATBOT (state, payload) {
    state.chatbot = payload
  },
  SET_CHATBOT_DETAIL (state, payload) {
    state.chatbot_detail = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getChatBotFlow (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT_BOT.GET_CHAT_BOT, payload)
      context.commit('SET_CHATBOT', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getChatBotFlowDetail (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT_BOT.GET_CHAT_BOT_DETAIL(payload))
      context.commit('SET_CHATBOT_DETAIL', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async checkIsHaveChild (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT_BOT.GET_CHAT_BOT_DETAIL(payload))
      return response.data.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async updateChatBotFlow (context, payload) {
    try {
      const response = await apiPutAuth(API_CHAT_BOT.UPDATE_CHAT_BOT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  chatbot_flow (state) {
    return state.chatbot
  },
  detail_chatbot_flow (state) {
    return state.chatbot_detail
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
