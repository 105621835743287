import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_STORE_MANAGEMENT, API_USER_MANAGEMENT } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    stores: { data: [] },
    detail_store: { data: {} },
    list_store_product: { data: [] },
    list_region: { data: [] },
    list_store_region: { data: [] },
    detail_store_product: { data: {} },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false
    },
    detail_user: {},
    status_sync: '',
    list_delivery_method: [],
    list_bulk_store: [],
    province: {},
    city: {},
    district: {},
    village: {},
    meta_filter: {},
    meta_filter2: {}
  }
}

const mutations = {
  SET_STORE (state, payload) {
    state.stores = payload
  },
  SET_DETAIL_STORE (state, payload) {
    state.detail_store = payload
  },
  SET_LIST_STORE_PRODUCT (state, payload) {
    state.list_store_product = payload
  },
  SET_LIST_REGION (state, payload) {
    state.list_region = payload
  },
  SET_LIST_STORE_REGION (state, payload) {
    state.list_store_region = payload
  },
  SET_DETAIL_STORE_PRODUCT (state, payload) {
    state.detail_store_product = payload
  },
  SET_LIST_DELIVERY_METHOD (state, payload) {
    state.list_delivery_method = payload
  },
  SET_LIST_BULK_STORE (state, payload) {
    state.list_bulk_store = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_STATUS_SYNC (state, payload) {
    state.status_sync = payload
  },
  SET_PROVINCE (state, payload) {
    state.province = payload
  },
  SET_CITY (state, payload) {
    state.city = payload
  },
  SET_DISTRICT (state, payload) {
    state.district = payload
  },
  SET_VILLAGE (state, payload) {
    state.village = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER2 (state, payload) {
    state.meta_filter2 = payload
  }
}

const actions = {
  async syncStore (context, payload) {
    try {
      const response = await apiPostAuth(API_STORE_MANAGEMENT.SYNC_STORE, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async syncStoreProduct (context, payload) {
    try {
      const response = await apiPostAuth(API_STORE_MANAGEMENT.SYNC_STORE_PRODUCT, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getStatusSyncStoreProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.STATUS_SYNC_STORE_PRODUCT, payload)
      context.commit('SET_STATUS_SYNC', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async previewBulkEditStore (context, payload) {
    try {
      const response = await apiPostAuth(API_STORE_MANAGEMENT.PREVIEW_BULK_STORE, payload)
      context.commit('SET_LIST_BULK_STORE', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async bulkStore (context, payload) {
    try {
      const response = await apiPostAuth(API_STORE_MANAGEMENT.BULK_STORE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListDeliveryMethod (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_DELIVERY_METHOD, payload)
      context.commit('SET_LIST_DELIVERY_METHOD', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListProvince (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_PROVINCE, payload)
      context.commit('SET_PROVINCE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListCity (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_CITY, payload)
      context.commit('SET_CITY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListDistrict (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_DISTRICT, payload)
      context.commit('SET_DISTRICT', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListVillage (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_VILLAGE, payload)
      context.commit('SET_VILLAGE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  // Detail User //
  async getDetailUser (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_USER(payload))
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  // Store Management //
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_STORE, payload)
      context.commit('SET_STORE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.DETAIL_STORE(payload))
      context.commit('SET_DETAIL_STORE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateStore (context, payload) {
    try {
      const response = await apiPutAuth(API_STORE_MANAGEMENT.UPDATE_STORE(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  // Store Region //
  async getListRegion (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_REGION, payload)
      context.commit('SET_LIST_REGION', response)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStoreRegion (context) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_REGION, {is_dropdown: true})
      context.commit('SET_LIST_STORE_REGION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDeleteRegion (context, payload) {
    try {
      const response = await apiDeleteAuth(API_STORE_MANAGEMENT.DELETE_REGION(payload))
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getUpdateRegion (context, payload) {
    try {
      const response = await apiPutAuth(API_STORE_MANAGEMENT.UPDATE_REGION(payload.id),{name: payload.name, status: payload.status} )
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getAddRegion (context, payload) {
    try {
      const response = await apiPostAuth(API_STORE_MANAGEMENT.ADD_REGION, payload )
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  // Store Product //
  async getListStoreProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.LIST_STORE_PRODUCT(payload.id), payload.reqBody)
      context.commit('SET_LIST_STORE_PRODUCT', response.data.data)
      context.commit('SET_META_FILTER2', payload.reqBody)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailStoreProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE_MANAGEMENT.DETAIL_STORE_PRODUCT(payload))
      context.commit('SET_DETAIL_STORE_PRODUCT', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateStoreProduct (context, payload) {
    try {
      const response = await apiPutAuth(API_STORE_MANAGEMENT.UPDATE_STORE_PRODUCT(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_store (state) {
    return state.stores
  },
  detail_store (state) {
    return state.detail_store
  },
  list_region (state) {
    return state.list_region
  },
  list_store_region (state) {
    return state.list_store_region
  },
  list_store_product (state) {
    return state.list_store_product
  },
  detail_store_product (state) {
    return state.detail_store_product
  },
  list_delivery_method (state) {
    return state.list_delivery_method
  },
  list_bulk_store (state) {
    return state.list_bulk_store
  },
  detail_user (state) {
    return state.detail_user
  },
  status_sync (state) {
    return state.status_sync
  },
  list_province (state) {
    return state.province
  },
  list_city (state) {
    return state.city
  },
  list_district (state) {
    return state.district
  },
  list_village (state) {
    return state.village
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
