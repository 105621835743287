import {apiGetAuth, apiPostAuth} from '@/utils/api'
import { API_PANDORA_DASHBOARD_ANALYTIC } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_message: { data: [], loading: false },
    message_impression_click: { data: [], loading: false },
    top_impress: { data: [], loading: false },
    top_click: { data: [], loading: false },
    bottom_impress: { data: [], loading: false },
    bottom_click: { data: [], loading: false },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUNTER_MESSAGE (state, payload) {
    state.counter_message = payload
  },
  SET_MESSAGE_IMPRESSION_CLICK (state, payload) {
    state.message_impression_click = payload
  },
  SET_TOP_IMPRESSION (state, payload) {
    state.top_impress = payload
  },
  SET_TOP_CLICK (state, payload) {
    state.top_click = payload
  },
  SET_BOTTOM_IMPRESS (state, payload) {
    state.bottom_impress = payload
  },
  SET_BOTTOM_CLICK (state, payload) {
    state.bottom_click = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getCounterMessage (context, payload) {
    try {
      context.commit('SET_COUNTER_MESSAGE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.COUNTER_MESSAGE, payload)
      context.commit('SET_COUNTER_MESSAGE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_COUNTER_MESSAGE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getMessageImpressClick (context, payload) {
    try {
      context.commit('SET_MESSAGE_IMPRESSION_CLICK', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.COUNTER_MESSAGE_IMPRESS_CLICK, payload)
      context.commit('SET_MESSAGE_IMPRESSION_CLICK', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_MESSAGE_IMPRESSION_CLICK', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getTopImpress (context, payload) {
    try {
      context.commit('SET_TOP_IMPRESSION', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MESSAGE_TOP_IMPRESS, payload)
      context.commit('SET_TOP_IMPRESSION', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_TOP_IMPRESSION', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getTopClick (context, payload) {
    try {
      context.commit('SET_TOP_CLICK', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MESSAGE_TOP_CLICK, payload)
      context.commit('SET_TOP_CLICK', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_TOP_CLICK', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getBottomImpress (context, payload) {
    try {
      context.commit('SET_BOTTOM_IMPRESS', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MESSAGE_BOTTOM_IMPRESS, payload)
      context.commit('SET_BOTTOM_IMPRESS', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_BOTTOM_IMPRESS', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getBottomClick (context, payload) {
    try {
      context.commit('SET_BOTTOM_CLICK', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MESSAGE_BOTTOM_CLICK, payload)
      context.commit('SET_BOTTOM_CLICK', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_BOTTOM_CLICK', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async shareCounterMessage (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_COUNTER_MESSAGE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMessageBottomClick (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MESSAGE_BOTTOM_CLICK, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMessageBottomImpress (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MESSAGE_BOTTOM_IMPRESS, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMessageImpressClick (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MESSAGE_IMPRESS_CLICK, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMessageTopImpress (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MESSAGE_TOP_IMPRESS, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMessageTopClick (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MESSAGE_TOP_CLICK, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTCounterMessage (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_COUNTER_MESSAGE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMessageImpressClick (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MESSAGE_IMPRESS_CLICK(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTTopMessageImpress (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MESSAGE_TOP_IMPRESS(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTTopMessageClik (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MESSAGE_TOP_CLICK(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTBottomMessageImpress (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MESSAGE_BOTTOM_IMPRESS(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTBottomMessageClik (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MESSAGE_BOTTOM_CLICK(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  counter_message (state) {
    return state.counter_message
  },
  message_impression_click (state) {
    return state.message_impression_click
  },
  top_impress (state) {
    return state.top_impress
  },
  top_click (state) {
    return state.top_click
  },
  bottom_impress (state) {
    return state.bottom_impress
  },
  bottom_click (state) {
    return state.bottom_click
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
