// https://www.npmjs.com/package/xlsx
import * as XLSX from 'xlsx'
import { getDataRole } from './auth'
import { notificationDanger } from '@/utils/notification'

const exportCsv = (dataTarget, targetName) => {
  if (dataTarget) {
    const csvFile = dataTarget
    const tagDownload = document.createElement("a")
    const blob = new Blob(["\ufeff", csvFile])
    const urlTarget = URL.createObjectURL(blob)

    tagDownload.href = urlTarget
    tagDownload.download = targetName + ".csv"

    document.body.appendChild(tagDownload)
    tagDownload.click()
    document.body.removeChild(tagDownload)
  }
  return null
}

const exportXLSX = (dataTarget, targetName) => {
  if (dataTarget) {
    const href = URL.createObjectURL(dataTarget)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    const downloadName = targetName + '.xlsx'
    link.setAttribute('download', downloadName) //or any other extension
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
  return null
}

const aksesManagement = (dataRole, action, type) => {
  if (dataRole?.length == 0 || dataRole == undefined) {
    if (type == 'component' || type == 'table') {
      return false
    } else {
      return 'red'
    }
  } else {
    if (action) {
      if (type == 'component' || type == 'table') {
        return false
      } 
      if (type == 'class') {
        return 'red'
      }
    } else {
      if (type == 'component' || type == 'table') {
        return true
      } 
      if (type == 'class') {
        return 'disabled'
      }
    }
  }
}

const findDetailUser = (data, param) => {
  if (data) {
    if (data?.length > 0) {
      return data?.find(item => item.id == param)?.username
    }
  }
}

const accessManagement2 = (action, loading) => {
  if (getDataRole().length < 1) return true
  if (!action || loading) return false
  return true
}

const logHistory = (action, menu, id, newData, oldData) => {
  if (action == 'unlocked' || action == 'locked' || action == 'unwithdrawal' || action == 'withdrawal') {
    return `${action} = ${id}, ${newData?.reason || ''}`
  } else if (action == 'linked-card') {
    return `${action} (${id}) = ${newData?.physical_card_id || ''}`
  } else if (action == 'unlinked-card') {
    return `${action} (${id}) = ${oldData?.physical_card_id || ''}`
  } else if (action == 'resend-verification') {
    return `${action} = ${id}`
  } else {
    return action
  }
}

// Get file name image for duplicate
const getFileNameURLImage = (dataImage) => {
  const splitImage = dataImage?.split('/')
  const getExt = splitImage[splitImage.length - 1]?.split('.')

  if (getExt[0].search('duplicate') > 0) {
    const countingFileName = getExt[0]?.split('_')
    if (countingFileName[countingFileName.length - 1] != '') {
      let currenCount = parseInt(countingFileName[countingFileName.length - 1])
      currenCount = currenCount + 1
      return `${countingFileName[0]}_${countingFileName[1]}_${currenCount}.${getExt[getExt.length - 1]}`
    } else {
      return `${getExt[0]}_1.${getExt[getExt.length - 1]}`
    }

  } else {
    return `${getExt[0]}_duplicate_1.${getExt[getExt.length - 1]}`
  }
}

const loopingSelect = (value, currentIndex, data) => {
  if (Object.keys(data.selectID).length > 0) {
    for (let i = 0; i < data.listApiID.length; i++) {
      if (i != currentIndex) {
        const tempValue = data.valueClick
        const findData = data.templateID.find(item => item.id == tempValue)
        if (findData != undefined) {
          const position = data.templateID.indexOf(findData)
          data.listApiID[i].splice(position, 0, findData)
        } 
        data.listApiID[i] = data.listApiID[i].filter(item => item.id != value)
      }
    }
  }
}

const CheckPhoneNumberID = (data, lengths) => {
  return data.phone_number.substring(0, lengths)
}

const truncateData = (data, lengths) => {
  const newTruncate = data.substring(0, lengths)
  if (data.length > lengths) {
    return newTruncate + '...'
  } else {
    return newTruncate
  }
}

const getFileSize = (e, maxSize) => {
  const fileSize =  e.target.files[0].size / 1000 / 1000
  if (fileSize > maxSize) {
    return false
  }
  return true
}

const timeOut = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const firstPage = {
  'ssosis': '/admin/dashboard/role-management',
  'cms': '/admin/aphrodite/store-management',
  'pandora': '/admin/pandora/sales-dashboard',
  'orion': '/admin/orion/category-management/parent-category'
}

const getStatusBgColor = {
  red: '#FDEDED',
  orange: '#FDF6ED',
  green: '#EEFBF7',
  grey: '#E1E3EA'
}

const getStatusColor = {
  red: '#E93535',
  orange: '#E89434',
  green: '#2CC995',
  grey: '#929292'
}

const checkStatusOrderLabel = (id) => {
  switch (id) {
  case 100: return {label: 'Waiting for Payment', color: 'orange'}
  case 110: return {label: 'Payment Expired', color: 'red'}
  case 111: return {label: 'Payment Failed', color: 'red'}
  case 200: return {label: 'Paid', color: 'green'}
  case 210: return {label: 'Cancel', color: 'red'}
  case 201: return {label: 'Stock Checked', color: 'orange'}
  case 300: return {label: 'Pick Confirmed & Ready to Delivery', color: 'green'}
  case 301: return {label: 'Pick Confirmed & Ready to Pick Up', color: 'green'}
  case 310: return {label: 'Delivery', color: 'green'}
  case 410: return {label: 'Delivery Incomplete', color: 'red'}
  case 400: return {label: 'Distribute to Customer', color: 'green'}
  default: return {label: ''}
  }
}

const checkStatusChat = (id) => {
  switch (id) {
  case 1: return {label: 'Unread', color: 'grey'}
  case 2: return {label: 'Read', color: 'orange'}
  case 3: return {label: 'Unsolved', color: 'red'}
  case 4: return {label: 'Solved', color: 'green'}
  default: return {label: ''}
  }
}

const getStatusPaymentColor = {
  'WAITING FOR PAYMENT': 'orange',
  'EXPIRED': 'red',
  'PAID': 'green',
  'FAILED': 'red'
}

const getStatusRefundColor = {
  'PENDING': 'orange', 
  'FAILED': 'red',
  'COMPLETED': 'green'
}

function debounce(fn, wait){
  let timer
  return function(...args){
    if(timer) {
      clearTimeout(timer) // clear any pre-existing timer
    }
    const context = this // get the current context
    timer = setTimeout(()=>{
      fn.apply(context, args) // call the function if time expires
    }, wait)
  }
}

const resetToggle = (type, setToggle, getToggle) => {
  setToggle({
    ...getToggle,
    cursor: type === 'set' ? 'no-drop' : ''
  })
}

const exportCSVTXT = (result, request, type, fileName) => {
  const link = document.createElement('a')
  link.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(result.data)
  link.setAttribute('download', `${fileName}.${type}`)
  link.click()
}

export const exportCSVTXT2 = (result, request, type, name) => {
  if (result?.data) {
    const link = document.createElement('a')
    link.href = 'data:application/csv;charset=utf-8,' + encodeURIComponent(result.data)
    link.setAttribute('download', `Export-${request.param}-${name}.${type}`)
    link.click()
  }
}

const downloadExcel = (data, fileName, sheetName, type = 'json') => {
  if (sheetName.length > 31) {
    notificationDanger('Sheet name too long')
    return
  }
  let workSheet
  if (type == 'aoa') {
    // aoa_to_sheet -> Create a worksheet from an array of array objects
    workSheet = XLSX.utils.aoa_to_sheet(data)
  } else {
    // json_to_sheet -> Create a worksheet from an array of JS objects
    workSheet = XLSX.utils.json_to_sheet(data)
  }
  
  // book_new -> creates an empty workbook with no worksheets.
  const workBook = XLSX.utils.book_new()
  // book_append_sheet -> Append a Worksheet to a Workbook
  // default -> XLSX.utils.book_append_sheet(workbook, worksheet, sheet_name, true) -> true: opt craete a unique name
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName, true)
  XLSX.writeFile(workBook,`${fileName}.xlsx`)
}

// param -> title, tipe, isPreview, header, data, valueform, getModalGlobal, setModalGlobal
const handlePrintAndPDF = (...args) => {
  const [title, type, preview, header, data, valueForm, getDataModalAddEditGLobal, setDataModalAddEditGlobal] = args
  setDataModalAddEditGlobal({
    ...getDataModalAddEditGLobal,
    data,
    valueForm,
    header,
    type,
    preview,
    title
  })
}

const getMedian = (impressLength, dataImpression) => {
  const sortedData = dataImpression.slice().sort((a, b) => a - b)
  if (impressLength % 2 == 0) {
    const medianGenap = impressLength/2
        
    const indexOfPlusOne = sortedData.find((_item,i) => i == medianGenap)
    const indexOf = sortedData.find((_item,i) => i == medianGenap - 1)

    const medianResult = indexOf + indexOfPlusOne

    let result = []
    for (let i = 0; i < impressLength; i++) {
      result.push(medianResult/2)
    }

    return result
  } else {
    const getMedianGanjil = impressLength/2
    const medianResult = Math.floor(getMedianGanjil)

    const indexOf = sortedData.find((_item,i) => i == medianResult)

    let result = []
    for (let i = 0; i < impressLength; i++) {
      result.push(indexOf)
    }

    return result
    
  }
}

const capitalizeFirstLetter = (str) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase()
}

const isImage = file => {
  return file.type.match(/image.*/)
}

const exportCustomHeaderAndResponseType = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link   = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export {
  exportCsv,
  exportXLSX,
  aksesManagement,
  findDetailUser,
  logHistory,
  getFileNameURLImage,
  loopingSelect,
  CheckPhoneNumberID,
  truncateData,
  getFileSize,
  timeOut,
  firstPage,
  getStatusBgColor,
  getStatusColor,
  checkStatusOrderLabel,
  checkStatusChat,
  debounce,
  accessManagement2,
  getStatusPaymentColor,
  getStatusRefundColor,
  resetToggle,
  exportCSVTXT,
  downloadExcel,
  handlePrintAndPDF,
  getMedian,
  capitalizeFirstLetter,
  isImage,
  exportCustomHeaderAndResponseType
}
