import { notificationDanger } from '@/utils/notification'
import { API_CHAT } from '@/utils/api-url'
import {apiPostAuth, apiGetAuth, apiPutAuth, apiDeleteAuth, apiGetAuthWithCustomHeadersResponseType} from '@/utils/api'
import router from '@/router'
import { dateGetOnlyFullTime, dateString } from '@/utils/formater'
import { exportCustomHeaderAndResponseType } from '@/utils/helper'


const state = () => {
  return {
    chatlive: { data: [] },
    detail_chatlive: { data: [] },
    user_chatlive: {},
    chat_template: {},
    detail_chat_template: {},
    end_chat_live: {},
    unread_count: {},
    categories: {},
    meta_filter: {},
    template_meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      targetModalTag: false,
      data: [],
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_CHATLIVE (state, payload) {
    state.chatlive = payload
  },
  SET_DETAIL_CHATLIVE (state, payload) {
    state.detail_chatlive = payload
  },
  SET_USER_CHATLIVE (state, payload) {
    state.user_chatlive = payload
  },
  SET_CHAT_TEMPLATE (state, payload) {
    state.chat_template = payload
  },
  SET_DETAIL_CHAT_TEMPLATE (state, payload) {
    state.detail_chat_template = payload
  },
  SET_UNREAD_COUNT (state, payload) {
    state.unread_count = payload
  },
  SET_END_CHAT_LIVE (state, payload) {
    state.end_chat_live = payload
  },
  SET_LIST_CATEGORY (state, payload) {
    state.categories = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_TEMPLATE_META_FILTER (state, payload) {
    state.template_meta_filter = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getListChatLive (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.LIST_CHAT_LIVE, payload)
      context.commit('SET_CHATLIVE', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'ChatLive', query: payload })
    } catch (error){
      notificationDanger(error)
    }
  },
  async getUserChatLive (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.USER_CHAT_LIVE(payload))
      context.commit('SET_USER_CHATLIVE', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailChatLive (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.DETAIL_CHAT_LIVE(`${payload.id}?page=${payload.page}&limit=${payload.limit}`))
      context.commit('SET_DETAIL_CHATLIVE', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getListChatTemplate (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.LIST_TEMPLATE_CHAT_LIVE, payload)
      context.commit('SET_CHAT_TEMPLATE', response.data.data)
      context.commit('SET_TEMPLATE_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailChatTemplate (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.LIST_TEMPLATE_DETAIL_CHAT_LIVE(payload))
      context.commit('SET_DETAIL_CHAT_TEMPLATE', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async createChatLive (context, payload) {
    try {
      const response = await apiPostAuth(API_CHAT.CREATE_CHAT_LIVE, payload)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async getUnreadCount (context) {
    try {
      const response = await apiGetAuth(API_CHAT.GET_UNREAD_COUNT)
      context.commit('SET_UNREAD_COUNT', response.data)
    } catch (error) {
      context.commit('SET_UNREAD_COUNT', { error: 'error' })
      notificationDanger(error)
    }
  },
  async endChatLive (context, payload) {
    try {
      const response = await apiPutAuth(API_CHAT.END_CHAT_LIVE(payload))
      context.commit('SET_END_CHAT_LIVE', response.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async createChatTemplate (context, payload) {
    try {
      const response = await apiPostAuth(API_CHAT.CREATE_TEMPLATE_CHAT_LIVE, payload)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async deleteChatTemplate (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CHAT.DELETE_TEMPLATE_CHAT_LIVE(payload))
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async editChatTemplate (context, payload) {
    try {
      const response = await apiPutAuth(API_CHAT.EDIT_TEMPLATE_CHAT_LIVE(payload.id), payload.body)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async subscribeTopicChatLive (context, payload) {
    try {
      const response = await apiPostAuth(API_CHAT.SUBSCRIBE_CHAT_LIVE, payload)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async exportChatLive (context, payload) {
    try {
      const response = await apiGetAuthWithCustomHeadersResponseType(API_CHAT.EXPORT_CHAT_LIVE, payload)
      
      exportCustomHeaderAndResponseType(response.data, `chatlive-export-${dateString(new Date())}-${dateGetOnlyFullTime(new Date())}.xlsx`)
    } catch (error){
      notificationDanger(error)
    }
  },
  async unsubscribeTopicChatLive (context, payload) {
    try {
      const response = await apiPostAuth(API_CHAT.UNSUBSCRIBE_CHAT_LIVE, payload)
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  },
  async getListCategory (context, payload) {
    try {
      const response = await apiGetAuth(API_CHAT.LIST_CATEGORY, payload)
      context.commit('SET_LIST_CATEGORY', response.data)
    } catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  list_chatlive (state) {
    return state.chatlive
  },
  detail_chatlive (state) {
    return state.detail_chatlive
  },
  user_chatlive (state) {
    return state.user_chatlive
  },
  chat_template (state) {
    return state.chat_template
  },
  detail_chat_template (state) {
    return state.detail_chat_template
  },
  unread_count (state) {
    return state.unread_count
  },
  end_chat_live (state) {
    return state.end_chat_live
  },
  list_category (state) {
    return state.categories
  },
  meta_filter (state) {
    return state.meta_filter
  },
  template_meta_filter (state) {
    return state.template_meta_filter
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
