import { apiGetAuth, apiPutAuth, apiPostAuth, apiDeleteAuth } from '@/utils/api'
import { API_CONSUMER } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    templates: { data: [] },
    detail_template: {
      isLoading: false,
      data: false
    },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_TEMPLATES (state, payload) {
    state.templates = payload
  },
  SET_DETAIL_TEMPLATE (state, payload) {
    state.detail_template = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListTemplate (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.TEMPLATE_HELP_CENTER, payload)
      context.commit('SET_TEMPLATES', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createTemplateHC (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.TEMPLATE_HELP_CENTER, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateTemplateHC (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.TEMPLATE_HELP_CENTER_BY_ID(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getDetailTemplateHC (context, payload) {
    try {
      context.commit('SET_DETAIL_TEMPLATE', {isLoading: true})
      const response = await apiGetAuth(API_CONSUMER.TEMPLATE_HELP_CENTER_BY_ID(payload))
      context.commit('SET_DETAIL_TEMPLATE', {data: response.data, isLoading: false})
    } catch (error) {
      context.commit('SET_DETAIL_TEMPLATE', {data: false, isLoading: false})
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteTemplate (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CONSUMER.TEMPLATE_HELP_CENTER_BY_ID(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_templates (state) {
    return state.templates
  },
  detail_template (state) {
    return state.detail_template
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
