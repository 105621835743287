import { apiGetAuth } from "@/utils/api"
import { API_CONSUMER } from "@/utils/api-url"
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    ratings: {
      isLoading: false,
      data: false
    },
    rating: {
      isLoading: false,
      data: false
    },
    meta_filter: {},
    data_modal_add_edit: {}
  }
}

const mutations = {
  SET_RATINGS (state, payload) {
    state.ratings = payload
  },
  SET_RATING (state, payload) {
    state.rating = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  }
}

const actions = {
  async getListRating (context, payload) {
    try {
      context.commit('SET_RATINGS', {isLoading: true})
      const response = await apiGetAuth(API_CONSUMER.RATING, payload)
      context.commit('SET_RATINGS', {data: response.data.data, isLoading: false})
      router.replace({ name: 'Rating', query: payload })
    } catch (error) {
      context.commit('SET_RATINGS', {data: false, isLoading: false})
      notificationDanger(error)
    }
  },
  async getDetailRating (context, payload) {
    try {
      context.commit('SET_RATING', {isLoading: true})
      const response = await apiGetAuth(API_CONSUMER.RATING_BY_ID(payload))
      context.commit('SET_RATING', {data: response.data.data, isLoading: false})
    } catch (error) {
      context.commit('SET_RATING', {data: false, isLoading: false})
      notificationDanger(error)
    }
  },
  async exportRating (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.EXPORT_RATING, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_rating (state) {
    return state.ratings
  },
  detail_rating (state) {
    return state.rating
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
