import { apiGetAuth, apiPutAuth, apiPostAuth } from '@/utils/api'
import { API_USER_MANAGEMENT, API_CONSUMER } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    consumer_offer: { data: []},
    detail_user: { data: []},
    pos_sales_history: { data: [] },
    consumer_tags: { data: [] },
    regions: { data: [] },
    genders: { data: [] },
    activity_log: { data: [] },
    consumers: { data: [] },
    detail_consumer: { data: {}},
    coupons_history: { data: []},
    meta_filter: {},
    meta_filter2: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_CONSUMER_OFFER (state, payload) {
    state.consumer_offer = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_POS_SALES_HISTORY (state, payload) {
    state.pos_sales_history = payload
  },
  SET_NEW_CONSUMER (state, payload) {
    state.new_consumer = payload
  },
  SET_LIST_CONSUMER_TAG (state, payload) {
    state.consumer_tags = payload
  },
  SET_REGION (state, payload) {
    state.regions = payload
  },
  SET_GENDER (state, payload) {
    state.genders = payload
  },
  SET_ACTIVITY_LOG (state, payload) {
    state.activity_log = payload
  },
  SET_CONSUMERS (state, payload) {
    state.consumers = payload
  },
  SET_DETAIL_CONSUMER (state, payload) {
    state.detail_consumer = payload
  },
  SET_LIST_COUPONS_HISTORY (state, payload) {
    state.coupons_history = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER2 (state, payload) {
    state.meta_filter2 = payload
  }
}

const actions = {
  async getExistingConsumerPhoneNUmber (context, payload) {
    try {
      // eslint-disable-next-line max-len
      const response = await apiGetAuth(API_CONSUMER.EXISTING_PHONE_NUMBER, payload)
      // const response = await apiGetAuth(API_CONSUMER.EXISTING_PHONE_NUMBER + `?phone_number=${payload.phone_number}`)
      return response.data.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListConsumerOffer (context, payload) {
    try {
      // eslint-disable-next-line max-len
      const response = await apiGetAuth(API_CONSUMER.CONSUMER_OFFER + `?consumer_id=${payload.consumer_id || ''}&page=${payload.page || ''}&limit=${payload.limit || ''}&region_id=${payload.region_id || ''}&gender_id=${payload.gender_id || ''}&date_of_birth=${payload.date_of_birth || ''}`)
      context.commit('SET_CONSUMER_OFFER', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListPosSalesHistory (context, payload) {
    const convertData = '?' + new URLSearchParams(payload).toString()
    try {
      // eslint-disable-next-line max-len
      // const response = await apiPostAuth(API_CONSUMER.POS_SALES_HISTORY + `?consumer_id=${payload.consumer_id || ''}&page=${payload.page || ''}&limit=${payload.limit || ''}&search=${payload.search || ''}&start_date=${payload.start_date || ''}&end_date=${payload.end_date || ''}`)
      const response = await apiPostAuth(API_CONSUMER.POS_SALES_HISTORY + convertData)
      
      context.commit('SET_POS_SALES_HISTORY', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListConsumerTag (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CONSUMER_TAG, payload)
      context.commit('SET_LIST_CONSUMER_TAG', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListRegion (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_REGION, payload)
      context.commit('SET_REGION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListGender (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_GENDER, payload)
      context.commit('SET_GENDER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListActivityLogByIDConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.CONSUMER_ACTIVITY_LOG(payload.id), payload.reqBody)
      context.commit('SET_ACTIVITY_LOG', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.LIST_CONSUMER, payload)
      context.commit('SET_CONSUMERS', response.data.data)
      context.commit('SET_META_FILTER2', payload)
      router.push({ name: 'Consumer', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailConsumer (context, payload) {
    try {
      const response = await apiGetAuth(API_CONSUMER.DETAIL_CONSUMER(payload))
      context.commit('SET_DETAIL_CONSUMER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListCouponHistory (context, payload) {
    try {
      // const response = await apiGetAuth(API_CONSUMER.DETAIL_CONSUMER(payload))
      context.commit('SET_LIST_COUPONS_HISTORY', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateConsumer (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.UPDATE_CONSUMER(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async withDrawConsumer (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.WITHDRAW_CONSUMER(payload.id), payload.body)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async unwithDrawConsumer (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.UNWITHDRAW_CONSUMER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async unLockAccountConsumer (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.UNLOCK_ACCOUNT_CONSUMER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async lockAccountConsumer (context, payload) {
    try {
      const response = await apiPutAuth(API_CONSUMER.LOCK_ACCOUNT_CONSUMER(payload.id), payload.body)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async resendEmail (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.RESEND_EMAIL_CONSUMER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async linkCard (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.LINK_CARD, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async unLinkCard (context, payload) {
    try {
      const response = await apiPostAuth(API_CONSUMER.UNLINK_CARD, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  consumer_offer (state) {
    return state.consumer_offer
  },
  detail_user (state) {
    return state.detail_user
  },
  list_pos_sales_history (state) {
    return state.pos_sales_history
  },
  list_consumer_tag (state) {
    return state.consumer_tags
  },
  list_region (state) {
    return state.regions
  },
  list_gender (state) {
    return state.genders
  },
  activity_log (state) {
    return state.activity_log
  },
  list_consumer (state) {
    return state.consumers
  },
  detail_consumer (state) {
    return state.detail_consumer
  },
  list_coupons_history (state) {
    return state.coupons_history
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter2 (state) {
    return state.meta_filter2
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
