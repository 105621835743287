import { apiGetAuth, apiPostAuth, apiPutAuth, apiPatchAuth } from '@/utils/api'
import { API_PLANOGRAM_MANAGEMENT, API_ORION_PRODUCT } from '@/utils/api-url'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    planogram: { data: [] },
    detail_planogram: { data: [] },
    data_modal_add_edit: {},
    meta_filter: {},
    products: {},
    sync: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_PLANOGRAM (state, payload) {
    state.planogram = payload
  },
  SET_DETAIL_PLANOGRAM (state, payload) {
    state.detail_planogram = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_PRODUCT (state, payload) {
    state.products = payload
  },
  SET_SYNC (state, payload) {
    state.sync = payload
  }
}

const actions = {
  async getListPlanogram (context, payload) {
    try{
      const response = await apiGetAuth(API_PLANOGRAM_MANAGEMENT.LIST_PLANOGRAM_MANAGEMENT, payload)
      context.commit('SET_PLANOGRAM', response.data)
    }catch (error){
      console.log(error)
    }
  },
  async getListProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_ORION_PRODUCT.LIST_PRODUCT, payload)
      if(response.data.data){
        context.commit('SET_PRODUCT', response.data.data)
      } else {
        context.commit('SET_PRODUCT', [])
      }
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getSync (context, payload) {
    try {
      const res_category = payload.cat.sub_category_id.length > 0? await apiPostAuth(API_PLANOGRAM_MANAGEMENT.SYNC_CATEGORY_PLANOGRAM_MANAGEMENT, payload.cat) : []
      const res_product = payload.pro.product_id.length > 0? await apiPostAuth(API_PLANOGRAM_MANAGEMENT.SYNC_PRODUCT_PLANOGRAM_MANAGEMENT, payload.pro) : []
      context.commit('SET_SYNC', {
        cat: res_category.data? res_category.data: [],
        pro: res_product.data? res_product.data : []
      })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailPlanogram (context, payload) {
    try {
      const response = await apiGetAuth(API_PLANOGRAM_MANAGEMENT.DETAIL_PLANOGRAM_MANAGEMENT(payload))
      context.commit('SET_DETAIL_PLANOGRAM', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createPlanogram (context, payload) {
    try {
      const response = await apiPostAuth(API_PLANOGRAM_MANAGEMENT.CREATE_PLANOGRAM_MANAGEMENT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updatePlanogram (context, payload) {
    try {
      const response = await apiPatchAuth(API_PLANOGRAM_MANAGEMENT.UPDATE_PLANOGRAM_MANAGEMENT(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async positionPlanogram (context, payload) {
    try {
      await apiPutAuth(API_PLANOGRAM_MANAGEMENT.POSITION_PLANOGRAM_MANAGEMENT, payload)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_planogram_management (state) {
    return state.planogram
  },
  detail_planogram_management (state) {
    return state.detail_planogram
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  },
  list_product (state) {
    return state.products
  },
  get_data_sync (state) {
    return state.sync
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
