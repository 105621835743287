import { apiPutAuth, apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_CATEGORY, API_USER_MANAGEMENT} from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    parent_category_list: { data: [] },
    detail_parent_category: { data: {} },
    updated_by: { data: {} },
    sub_category_tag: { data: [] },
    sub_category_list: { data: [] },
    detail_sub_category: { data: {} },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_PARENT_CATEGORY_LIST(state, payload) {
    state.parent_category_list = payload
  },
  SET_DETAIL_PARENT_CATEGORY(state, payload) {
    state.detail_parent_category = payload
  },
  SET_UPDATED_BY(state, payload) {
    state.updated_by = payload
  },
  SET_SUB_CATEGORY_TAG(state, payload) {
    state.sub_category_tag = payload
  },
  SET_SUB_CATEGORY_LIST(state, payload) {
    state.sub_category_list = payload
  },
  SET_DETAIL_SUB_CATEGORY(state, payload) {
    state.detail_sub_category = payload
  },

  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD(state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER(state, payload) {
    state.meta_filter = payload
  },
  SET_DATA_MODAL_ADD_EDIT(state, payload) {
    state.data_modal_add_edit = payload
  }
}

const actions = {
  async getListSubCategory(context, payload) {
    try {
      const response = await apiGetAuth(API_CATEGORY.LIST_SUB_CATEGORY, payload)
      context.commit('SET_SUB_CATEGORY_LIST', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'SubCategory', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailSubCategory(context, payload) {
    context.commit('SET_DETAIL_SUB_CATEGORY', {})
    try {
      const response = await apiGetAuth(API_CATEGORY.DETAIL_SUB_CATEGORY(payload))
      context.commit('SET_DETAIL_SUB_CATEGORY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateSubCategory(context, payload) {
    try {
      const response = await apiPutAuth(API_CATEGORY.UPDATE_SUB_CATEGORY(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListParentCategory(context, payload) {
    try {
      const response = await apiGetAuth(API_CATEGORY.LIST_PARENT_CATEGORY, payload)
      context.commit('SET_PARENT_CATEGORY_LIST', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'ParentCategory', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailParentCategory(context, payload) {
    context.commit('SET_DETAIL_PARENT_CATEGORY', {})
    try {
      const response = await apiGetAuth(API_CATEGORY.DETAIL_PARENT_CATEGORY(payload))
      context.commit('SET_DETAIL_PARENT_CATEGORY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createParentCategory(context, payload) {
    try {
      const response = await apiPostAuth(API_CATEGORY.CREATE_PARENT_CATEGORY, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getTagSubCategory(context) {
    try {
      const response = await apiGetAuth(API_CATEGORY.LIST_SUB_CATEGORY, { limit: 999 })
      context.commit('SET_SUB_CATEGORY_TAG', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateParentCategory(context, payload) {
    try {
      const response = await apiPutAuth(API_CATEGORY.UPDATE_PARENT_CATEGORY(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getUpdatedBy(context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_USER(payload))
      context.commit('SET_UPDATED_BY', response.data.data)
    } catch (error) {
      console.log(error)
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async positionParentCategory(context, payload) {
    try {
      await apiPutAuth(API_CATEGORY.POSITION_PARENT_CATEGORY, payload)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  get_toggle_modal_add_edit_discard(state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter(state) {
    return state.meta_filter
  },
  get_data_modal_add_edit(state) {
    return state.data_modal_add_edit
  },
  list_sub_category(state) {
    return state.sub_category_list
  },
  detail_sub_category(state) {
    return state.detail_sub_category
  },
  list_parent_category(state) {
    return state.parent_category_list
  },
  detail_parent_category(state) {
    return state.detail_parent_category
  },
  tag_sub_category(state) {
    return state.sub_category_tag
  },
  updated_by(state) {
    return state.updated_by
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
