import { API_TOP_UP_BILLS } from '@/utils/api-url'
import { apiGetAuth } from '@/utils/api'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    digital_product: { 
      isLoading: false,
      data: []
    },
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DIGITAL_PRODUCT (state, payload) {
    state.digital_product = {...payload}
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListDigitalProduct(context, payload) {
    context.commit('SET_DIGITAL_PRODUCT', {isLoading: true, data: []})
    try {
      const response = await apiGetAuth(API_TOP_UP_BILLS.LIST_DIGITAL_PRODUCT, payload)
      context.commit('SET_DIGITAL_PRODUCT', {isLoading: false, data: response.data.data})
    } catch (error) {
      context.commit('SET_DIGITAL_PRODUCT', {isLoading: false, data: []})
      notificationDanger(error)
    }
  }
}

const getters = {
  list_digital_product (state) {
    return state.digital_product
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
