import { apiPutAuth, apiGetAuth, apiPostAuth } from '@/utils/api'
import { API_MASTER_DATA, API_LOG, API_USER_MANAGEMENT } from '@/utils/api-url'
// import { API_MASTER_DATA, API_CONSUMER } from '@/utils/api-url'
import router from '@/router'
import {
  notificationDanger
} from '@/utils/notification'
import { exportCsv } from '@/utils/helper'
// import { exportExcel } from '@/utils/helper'

const state = () => {
  return {
    product_category: { data: [] },
    products: { data: [] },
    product_detail: { data: [] },
    product_edit: { data: [] },
    product_list_store: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    },
    preview_bulk_edit : { data: [] },
    status_sync: '',
    status_bulk: '',
    detail_user: { data: []}
  }
}

const mutations = {
  SET_LOG_CATEGORY (state, payload) {
    state.product_category = payload
  },
  SET_LOG (state, payload) {
    state.products = payload
  },
  SET_LOG_DETAIL (state, payload) {
    state.product_detail = payload
  },
  SET_LOG_LIST_STORE (state, payload) {
    state.product_list_store = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_PREVIEW_BULK_EDIT (state, payload) {
    state.preview_bulk_edit = payload
  },
  SET_STATUS_SYNC (state, payload) {
    state.status_sync = payload
  },
  SET_STATUS_BULK (state, payload) {
    state.status_bulk = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  }
}

const actions = {
  async getListLogCategory (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_DATA.LIST_LOG_CATEGORY, payload)
      context.commit('SET_LOG_CATEGORY', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListLog (context, payload) {
    console.log(payload)
    try {
      const response = await apiGetAuth(API_LOG.COSTUMER_LOGIN_LOG, payload)
      context.commit('SET_LOG', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({ name: 'ConsumerLoginLog', query: payload })
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getLogDetail (context, payload) {
    try {
      const response = await apiGetAuth(API_LOG.DETAIL_LOG(payload))
      context.commit('SET_LOG_DETAIL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getLogEdit (context, payload) {
    try {
      const response = await apiGetAuth(API_LOG.DETAIL_LOG(payload))
      context.commit('SET_LOG_DETAIL', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getLogListStore (context, payload) {
    const params = {
      id: payload.id,
      page: payload.page,
      limit: payload.limit
    }
    try {
      const response = await apiGetAuth(API_LOG.LIST_STORE(params))
      context.commit('SET_LOG_LIST_STORE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateLog (context, payload) {
    try {
      const response = await apiPutAuth(API_LOG.UPDATE_LOG(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async previewBulkEdit (context, payload) {
    try {
      const response = await apiPostAuth(API_LOG.PREVIEW_BULK_EDIT, payload)
      context.commit('SET_PREVIEW_BULK_EDIT', response.data)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      return error
    }
  },
  async submitBulkEdit (context, payload) {
    try {
      const response = await apiPostAuth(API_LOG.SUBMIT_BULK_EDIT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async syncData (context, payload) {
    try {
      console.log(context)
      const response = await apiPostAuth(API_LOG.SYNC_LOG, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getStatusSyncLog (context, payload) {
    try {
      const response = await apiGetAuth(API_LOG.STATUS_SYNC_LOG, payload)
      context.commit('SET_STATUS_SYNC', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getStatusBulk (context, payload) {
    try {
      const response = await apiGetAuth(API_LOG.STATUS_BULK, payload)
      context.commit('SET_STATUS_BULK', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportLog (context, payload) {
    try {
      const response = await apiGetAuth(API_LOG.EXPORT_LOG, payload)
      exportCsv(response.data, 'export_log')
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getDetailUser (context, payload) {
    try {
      const response = await apiGetAuth(API_USER_MANAGEMENT.DETAIL_USER(payload))
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_log_category (state) {
    return state.product_category
  },
  list_log (state) {
    return state.products
  },
  get_log_detail (state) {
    return state.product_detail
  },
  get_log_list_store (state) {
    return state.product_list_store
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_preview_bulk_edit (state) {
    return state.preview_bulk_edit
  },
  status_sync (state) {
    return state.status_sync
  },
  status_bulk (state) {
    return state.status_bulk
  },
  detail_user (state) {
    return state.detail_user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
