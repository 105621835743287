import {apiPostAuth, apiDeleteAuth, apiGetAuth, apiPutAuth} from '@/utils/api'
import { API_USER_MANAGEMENT, API_CAMPAIGN } from '@/utils/api-url'
import router from '@/router'

import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    detail_user: { data: []},
    tags: { data: [] },
    campaigns: { data: [] },
    new_banner: { data: [] },
    banners: { data: [] },
    detail_banners: { data: {}},
    products: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_NEW_BANNER (state, payload) {
    state.new_banner = payload
  },
  SET_BANNER (state, payload) {
    state.banners = payload
  },
  SET_DETAIL_BANNER (state, payload) {
    state.detail_banners = payload
  },
  SET_PRODUCT (state, payload) {
    state.products = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListBanner(context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_BANNER, payload)
      context.commit('SET_BANNER', response.data.data)
      context.commit('SET_META_FILTER', payload)
      router.push({name: 'Banners', query: payload})
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createBanner (context, payload) {
    try {
      const response = await apiPostAuth(API_CAMPAIGN.CREATE_BANNER, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateBanner (context, payload) {
    try {
      const response = await apiPutAuth(API_CAMPAIGN.UPDATE_BANNER(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getDetailBanner (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.DETAIL_BANNER(payload))
      context.commit('SET_DETAIL_BANNER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async deleteBanner (context, payload) {
    try {
      const response = await apiDeleteAuth(API_CAMPAIGN.DELETE_BANNER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,
        title: '',
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_CAMPAIGN.LIST_PRODUCT, payload)
      context.commit('SET_PRODUCT', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  detail_user (state) {
    return state.detail_user
  },
  list_new_banner (state) {
    return state.new_banner
  },
  list_banner (state) {
    return state.banners
  },
  detail_banners (state) {
    return state.detail_banners
  },
  list_product (state) {
    return state.products
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
