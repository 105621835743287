import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { API_E_VOUCHER } from '@/utils/api-url'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_bulk_voucher: { data: [] },
    list_client: { data: [] },
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false,
      code: null,
      limitUse: 0
    }
  }
}

const mutations = {
  SET_BULK_VOUCHER (state, payload) {
    state.list_bulk_voucher = payload
  },
  SET_CLIENT (state, payload) {
    state.list_client = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListClient (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_CLIENT_E_VOUCHER, payload)
      context.commit('SET_CLIENT', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async updateClient (context, payload) {
    try{
      const response = await apiPutAuth(API_E_VOUCHER.CLIENT_E_VOUCHER + '?code=' + payload.code, {release_amount: payload.release_amount})
      notificationSuccess('Edit Data Successful')
      return response
    }catch (error){
      notificationDanger(error)
    }
  },
  async getListBulkVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_BULK_E_VOUCHER, payload)
      context.commit('SET_BULK_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async releaseBulkVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.RELEASE_BULK_E_VOUCHER + '?order_number=' + payload.code)
      notificationSuccess('Release Bulk Voucher is Successful')
      return response
    }catch (error){
      notificationDanger(error)
    }
  },
  async verifyBulkVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.VERIFY_BULK_E_VOUCHER + '?order_number=' + payload.code)
      notificationSuccess('Payment Verify Bulk Voucher is Successful')
      return response
    }catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  list_bulk_voucher (state) {
    return state.list_bulk_voucher
  },
  list_client (state) {
    return state.list_client
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
