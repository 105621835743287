import {apiGetAuth, apiPostAuth} from '@/utils/api'
import {API_PANDORA_DASHBOARD_ANALYTIC} from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_sales_summary: { data: [], loading: false },
    list_top_store: { data: [], loading: false },
    list_bottom_store: { data: [], loading: false },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_SALES_SUMMARY (state, payload) {
    state.list_sales_summary = payload
  },
  SET_TOP_STORE (state, payload) {
    state.list_top_store = payload
  },
  SET_BOTTOM_STORE (state, payload) {
    state.list_bottom_store = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getSummary (context, payload) {
    try {
      context.commit('SET_SALES_SUMMARY', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.SUMMARY, payload)
      context.commit('SET_SALES_SUMMARY', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_SALES_SUMMARY', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getTopStore (context, payload) {
    try {
      context.commit('SET_TOP_STORE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.TOP_STORE, payload)
      context.commit('SET_TOP_STORE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_TOP_STORE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getBottomStore (context, payload) {
    try {
      context.commit('SET_BOTTOM_STORE', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.BOTTOM_STORE, payload)
      context.commit('SET_BOTTOM_STORE', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_BOTTOM_STORE', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  
  async exportCSVTXTSalesSummary (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_SALES_SUMMARY(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTTopStore (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_TOP_STORE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTBottomStore (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_BOTTOM_STORE(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTBottomBannerImpress (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_BANNER_BOTTOM_IMPRESS(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTBottomBannerClik (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_BANNER_BOTTOM_CLICK(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareSalesSummary(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_SALES_SUMMARY, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareSalesTop(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_SALES_TOP, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareSalesBottom(context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_SALES_BOTTOM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_sales_summary (state) {
    return state.list_sales_summary
  },
  list_top_store (state) {
    return state.list_top_store
  },
  list_bottom_store (state) {
    return state.list_bottom_store
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
