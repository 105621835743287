import { apiDeleteAuth, apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_FREE_GIFT } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    free_gift: { data: [] },
    detail_free_gift: { data: [] },
    detail_user: { created_by: [], updated_by: [] },
    products: { data: [] },
    region: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_FREE_GIFT (state, payload) {
    state.free_gift = payload
  },
  SET_DETAIL_FREE_GIFT (state, payload) {
    state.detail_free_gift = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_PRODUCT (state, payload) {
    state.products = payload
  },
  SET_REGION (state, payload) {
    state.region = payload
  }
}

const actions = {
  async getListFreeGift (context, payload) {
    try {
      const response = await apiGetAuth(API_FREE_GIFT.LIST_FREE_GIFT, payload)
      context.commit('SET_FREE_GIFT', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailFreeGift (context, payload) {
    try {
      const response = await apiGetAuth(API_FREE_GIFT.DETAIL_FREE_GIFT(payload))
      context.commit('SET_DETAIL_FREE_GIFT', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createFreeGift (context, payload) {
    try {
      const response = await apiPostAuth(API_FREE_GIFT.CREATE_FREE_GIFT, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateFreeGift (context, payload) {
    try {
      const response = await apiPutAuth(API_FREE_GIFT.UPDATE_FREE_GIFT(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteFreeGift (context, payload) {
    try {
      const response = await apiDeleteAuth(API_FREE_GIFT.DELETE_FREE_GIFT(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_FREE_GIFT.LIST_PRODUCT, payload)
      context.commit('SET_PRODUCT', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListRegion (context) {
    try {
      const response = await apiGetAuth(API_FREE_GIFT.LIST_REGION)
      context.commit('SET_REGION', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async bulkUploadStore(context, payload) {
    try {
      const response = await apiPostAuth(API_FREE_GIFT.BULK_UPLOAD_STORE, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getStoreByRegion(context, payload) {
    try {
      const response = await apiGetAuth(API_FREE_GIFT.LIST_STORE_BY_REGION, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_free_gift (state) {
    return state.free_gift
  },
  detail_free_gift (state) {
    return state.detail_free_gift
  },
  detail_user (state) {
    return state.detail_user
  },
  list_product (state) {
    return state.products
  },
  list_region (state) {
    return state.region
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
