import { apiGetAuth } from '@/utils/api'
import { notificationDanger } from '@/utils/notification'
import { API_SALES_DASHBOARD } from '@/utils/api-url'

const state = () => {
  return {
    sales_member: { graph_sales_member: [], table_sales_member: [] },
    member_transaction: { graph_member_transaction: [], table_member_transaction: [] },
    apc_member_store: { graph_apc_member: [], table_apc_member: [] },
    average_transaction: { graph_avg_transaction: [], table_avg_transaction: [] },
    summary_sales_report: { graph_summary_sales_report: [], table_summary_sales_report: [] },
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}


const mutations = {
  SET_SALES_MEMBER(state, payload) {
    state.sales_member = payload
  },
  SET_MEMBER_TRANSACTION(state, payload) {
    state.member_transaction = payload
  },
  SET_APC_MEMBER_STORE(state, payload) {
    state.apc_member_store = payload
  },
  SET_AVERAGE_TRANSACTION(state, payload) {
    state.average_transaction = payload
  },
  SET_SUMMARY_SALES_REPORT(state, payload) {
    state.summary_sales_report = payload
  },
  SET_DATA_MODAL_ADD_EDIT(state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD(state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getSalesMember (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.SALES_MEMBER, payload)
      context.commit('SET_SALES_MEMBER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTSalesMember (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.EXPORT_SALES_MEMBER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getMemberTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.MEMBER_TRANSACTION, payload)
      context.commit('SET_MEMBER_TRANSACTION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMemberTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.EXPORT_MEMBER_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getApcMemberStore (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.APC_MEMBER, payload)
      context.commit('SET_APC_MEMBER_STORE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTApcMemberStore (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.EXPORT_APC_MEMBER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getAverageTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.AVERAGE_TRANSACTION, payload)
      context.commit('SET_AVERAGE_TRANSACTION', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTAverageTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.EXPORT_AVERAGE_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getSummarySalesReport (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.SUMMARY_SALES_REPORT, payload)
      context.commit('SET_SUMMARY_SALES_REPORT', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTSummarySalesReport (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.EXPORT_SUMMARY_SALES_REPORT(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  get_sales_member(state) {
    return state.sales_member
  },
  get_member_transaction(state) {
    return state.member_transaction
  },
  get_apc_member_store(state) {
    return state.apc_member_store
  },
  get_average_transaction(state) {
    return state.average_transaction
  },
  get_summary_sales_report(state) {
    return state.summary_sales_report
  },
  get_toggle_modal_add_edit_discard(state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit(state) {
    return state.data_modal_add_edit
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
