import { notificationDanger } from '@/utils/notification'
import { apiGetAuth } from '@/utils/api'
import { API_DIGITAL_COUPON } from '@/utils/api-url'

const state = () => {
  return {
    coupon_log: { data: [] },
    detail_coupon_log: { data: [] },
    coupon_list: { data: [] },
    meta_filter: {},
    meta_filter_coupon: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUPON_LOG (state, payload) {
    state.coupon_log = payload
  },
  SET_DETAIL_COUPON_LOG (state, payload) {
    state.detail_coupon_log = payload
  },
  SET_COUPON_LIST (state, payload) {
    state.coupon_list = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER_COUPON (state, payload) {
    state.meta_filter_coupon = payload
  }
}

const actions = {
  async getListCouponLog (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.LIST_COUPON_LOG, payload)
      context.commit('SET_COUPON_LOG', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailCouponLog (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.DETAIL_COUPON_LOG(payload))
      context.commit('SET_DETAIL_COUPON_LOG', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCouponList (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.DETAIL_COUPON_LOG_COUPON_LIST(payload.id), payload.reqBody)
      context.commit('SET_COUPON_LIST', response.data.data)
      context.commit('SET_META_FILTER_COUPON', payload.reqBody)
    } catch (error){
      notificationDanger(error)
    }
  },
  async exportCouponLog (context, payload) {
    const param = '?' + new URLSearchParams(payload).toString()
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.EXPORT_COUPON_LOG(param))
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOrderId (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.GET_ORDER_ID(payload))
      return response.data
    } catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  list_coupon_log (state) {
    return state.coupon_log
  },
  detail_coupon_log (state) {
    return state.detail_coupon_log
  },
  coupon_list (state) {
    return state.coupon_list
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter_coupon (state) {
    return state.meta_filter_coupon
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
