import {apiGetAuth, apiPostAuth} from '@/utils/api'
import { API_PANDORA_DASHBOARD_ANALYTIC } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    counter_marketing: { data: [], loading: false },
    top_offer: { data: [], loading: false },
    campaigns: { data: [], loading: false },
    offers: { data: [] },
    offers_limit: { data: [] },
    offers_all: { data: [] },
    banners_all: { data: [] },
    message_all: { data: []},
    store: { data: [] },
    top_banner: { data: [], loading: false },
    unique_transaction: { data: [], loading: false },
    sales: { data: [], loading: false },
    average_transaction: { data: [], loading: false },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUNTER_MARKETING (state, payload) {
    state.counter_marketing = payload
  },
  SET_CAMPAIGN (state, payload) {
    state.campaigns = payload
  },
  SET_OFFER (state, payload) {
    state.offers = payload
  },
  SET_OFFER_LIMIT (state, payload) {
    state.offers_limit = payload
  },
  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_OFFER_ALL (state, payload) {
    state.offers_all = payload
  },
  SET_BANNER_ALL (state, payload) {
    state.banners_all = payload
  },
  SET_MESSAGE_ALL (state, payload) {
    state.message_all = payload
  },
  SET_TOP_OFFER (state, payload) {
    state.top_offer = payload
  },
  SET_TOP_BANNER (state, payload) {
    state.top_banner = payload
  },
  SET_UNIQUE_TRANSACTION (state, payload) {
    state.unique_transaction = payload
  },
  SET_SALES(state, payload) {
    state.sales = payload
  },
  SET_AVERAGE_TRANSACTION(state, payload) {
    state.average_transaction = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListCampaign (context, payload) {
    try {
      context.commit('SET_CAMPAIGN', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_CAMPAIGN, payload)
      context.commit('SET_CAMPAIGN', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_CAMPAIGN', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListBannerAll (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_BANNER, payload)
      context.commit('SET_BANNER_ALL', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListMessageAll (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_MESSAGE, payload)
      context.commit('SET_MESSAGE_ALL', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListOfferAll (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_OFFER, payload)
      context.commit('SET_OFFER_ALL', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListOffer (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_OFFER, payload)
      context.commit('SET_OFFER', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListOfferLimit (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.LIST_OFFER_LIMIT, payload)
      context.commit('SET_OFFER_LIMIT', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCounterMarketing (context, payload) {
    try {
      context.commit('SET_COUNTER_MARKETING', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_COUNTER, payload)
      context.commit('SET_COUNTER_MARKETING', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_COUNTER_MARKETING', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getTopOffer (context, payload) {
    try {
      context.commit('SET_TOP_OFFER', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_TOP_REDEEM, payload)
      context.commit('SET_TOP_OFFER', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_TOP_OFFER', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getTopBanner (context, payload) {
    try {
      context.commit('SET_TOP_BANNER', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_TOP_CLICK, payload)
      context.commit('SET_TOP_BANNER', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_TOP_BANNER', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getUniqueTransaction (context, payload) {
    try {
      context.commit('SET_UNIQUE_TRANSACTION', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_UNIQUE_TRANSACTION, payload)
      context.commit('SET_UNIQUE_TRANSACTION', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_UNIQUE_TRANSACTION', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getSales (context, payload) {
    try {
      context.commit('SET_SALES', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_SALES, payload)
      context.commit('SET_SALES', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_SALES', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getAverageTransaction (context, payload) {
    try {
      context.commit('SET_AVERAGE_TRANSACTION', {loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MARKETING_AVG_TRANSACTION, payload)
      context.commit('SET_AVERAGE_TRANSACTION', {data: data.data, loading: false})
    } catch (error) {
      context.commit('SET_AVERAGE_TRANSACTION', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingBanners (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_TOP_BANNER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingOffers (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_TOP_OFFER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingCounter (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_COUNTER(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingUniqueTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_UNIQUE_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingAvgTransaction (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_AVG_TRANSACTION(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async exportCSVTXTMarketingSales (context, payload) {
    try {
      const response = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.EXPORT_MARKETING_SALES(payload.param), payload.reqBody)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingCounter (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_COUNTER, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingAverage (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_AVERAGE, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingSales (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_SALES, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingUnique (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_UNIQUE_TRANSACTION, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingOffers (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_TOP_REDEEM, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async shareMarketingBanners (context, payload) {
    try {
      const response = await apiPostAuth(API_PANDORA_DASHBOARD_ANALYTIC.SHARE_MARKETING_TOP_CLICK, payload)
      return response
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  counter_marketing (state) {
    return state.counter_marketing
  },
  campaigns (state) {
    return state.campaigns
  },
  offers (state) {
    return state.offers
  },
  store (state) {
    return state.store
  },
  offers_all (state) {
    return state.offers_all
  },
  offers_limit (state) {
    return state.offers_limit
  },
  banners_all (state) {
    return state.banners_all
  },
  message_all (state) {
    return state.message_all
  },
  top_banner (state) {
    return state.top_banner
  },
  top_offer (state) {
    return state.top_offer
  },
  unique_transaction (state) {
    return state.unique_transaction
  },
  sales (state) {
    return state.sales
  },
  average_transaction (state) {
    return state.average_transaction
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
