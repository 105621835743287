import { apiGetAuth, apiPatchAuth } from '@/utils/api'
import { API_3PL_SETTING } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    three_pl: { data: [] },
    detail_three_pl: { data: [] },
    data_modal_add_edit: {},
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_3PL_SETTING (state, payload) {
    state.three_pl = payload
  },
  SET_DETAIL_3PL_SETTING (state, payload) {
    state.detail_three_pl = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getList3PL (context, payload) {
    try{
      const response = await apiGetAuth(API_3PL_SETTING.LIST_3PL_SETTING, payload)
      context.commit('SET_3PL_SETTING', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetail3PL (context, payload) {
    try {
      const response = await apiGetAuth(API_3PL_SETTING.DETAIL_3PL_SETTING(payload))
      context.commit('SET_DETAIL_3PL_SETTING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async update3PL (context, payload) {
    try {
      const response = await apiPatchAuth(API_3PL_SETTING.UPDATE_3PL_SETTING, payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_3pl_setting (state) {
    return state.three_pl
  },
  detail_3pl_setting (state) {
    return state.detail_three_pl
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
