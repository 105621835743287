import { apiGetAuth, apiPutAuth, apiPostAuth } from '@/utils/api'
import { API_MASTER_ALP } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_member_alp: { data: [] },
    detail_member_alp: {},
    history_member_alp: {},
    bulk_member_alp: [],
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loading: false
    }
  }
}

const mutations = {
  SET_LIST_MEMBER_ALP (state, payload) {
    state.list_member_alp = payload
  },
  SET_DETAIL_MEMBER_ALP (state, payload) {
    state.detail_member_alp = payload
  },
  SET_HISTORY_MEMBER_ALP (state, payload) {
    state.history_member_alp = payload
  },
  SET_BULK_MEMBER_ALP(state, payload) {
    state.bulk_member_alp = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListMemberAlp (context, payload) {
    try{
      const response = await apiGetAuth(API_MASTER_ALP.LIST_MEMBER_ALP, payload)
      context.commit('SET_LIST_MEMBER_ALP', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetailMemberAlp (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_ALP.DETAIL_MEMBER_ALP(payload.employeeNo), {is_check: payload.is_check})
      context.commit('SET_DETAIL_MEMBER_ALP', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getHistoryMemberAlp (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_ALP.HISTORY_MEMBER_ALP(payload.associateId))
      context.commit('SET_HISTORY_MEMBER_ALP', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateMemberAlp (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_ALP.UPDATE_MEMBER_ALP, payload)
      return response
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async uploadBulk (context, payload){
    try {
      const response = await apiPostAuth(API_MASTER_ALP.UPLOAD_BULK_MEMBER_ALP, payload)
      context.commit('SET_BULK_MEMBER_ALP', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async confirmBulk (context, payload){
    try {
      const response = await apiPostAuth(API_MASTER_ALP.CONFIRM_BULK_MEMBER_ALP, payload)
      return response
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_member_alp (state) {
    return state.list_member_alp
  },
  detail_member_alp (state) {
    return state.detail_member_alp
  },
  history_member_alp (state) {
    return state.history_member_alp
  },
  bulk_member_alp (state) {
    return state.bulk_member_alp
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
