import { apiGetAuth, apiPostAuth, apiDeleteAuth } from '@/utils/api'
import { notificationDanger } from '@/utils/notification'
import { API_DIGITAL_COUPON } from '@/utils/api-url'

const state = () => {
  return {
    raffle: { data: [] },
    detail_raffle: { data: [] },
    coupon: { data: [] },
    meta_filter: {},
    meta_filter_coupon: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_RAFFLE (state, payload) {
    state.raffle = payload
  },
  SET_DETAIL_RAFFLE (state, payload) {
    state.detail_raffle = payload
  },
  SET_COUPON (state, payload) {
    state.coupon = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_META_FILTER_COUPON (state, payload) {
    state.meta_filter_coupon = payload
  }
}

const actions = {
  async getListRaffle (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.LIST_RAFFLE, payload)
      context.commit('SET_RAFFLE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailRaffle (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.DETAIL_RAFFLE(payload))
      context.commit('SET_DETAIL_RAFFLE', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getCouponList (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.LIST_RAFFLE_COUPON, payload)
      context.commit('SET_COUPON', response.data.data)
      context.commit('SET_META_FILTER_COUPON', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async createRaffle (context, payload) {
    try {
      const response = await apiPostAuth(API_DIGITAL_COUPON.CREATE_RAFFLE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteRaffle (context, payload) {
    try {
      const response = await apiDeleteAuth(API_DIGITAL_COUPON.DESTROY_RAFFLE(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async exportRaffle (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.EXPORT_RAFFLE(payload))
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_raffle (state) {
    return state.raffle
  },
  detail_raffle (state) {
    return state.detail_raffle
  },
  list_coupon (state) {
    return state.coupon
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  },
  meta_filter_coupon (state) {
    return state.meta_filter_coupon
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
