import { apiGetAuth } from '@/utils/api'
import { API_E_VOUCHER } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_voucher: { data: [] },
    e_voucher_glossary: { data: [] },
    e_voucher_client: { data: [] },
    e_voucher_items: { data: [] },
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_E_VOUCHER (state, payload) {
    state.list_voucher = payload
  },
  SET_GLOSSARY (state, payload) {
    state.e_voucher_glossary = payload
  },
  SET_CLIENT (state, payload) {
    state.e_voucher_client = payload
  },
  SET_ITEMS (state, payload) {
    state.e_voucher_items = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListEVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_E_VOUCHER, payload)
      context.commit('SET_E_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getOutstandingEVoucherItems (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.OUTSTANDING_E_VOUCHER_REPORT, payload)
      context.commit('SET_E_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getEVoucherGlossary (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.GLOSSARY_E_VOUCHER, payload)
      context.commit('SET_GLOSSARY', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getEVoucherClient (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.CLIENT_E_VOUCHER, payload)
      context.commit('SET_CLIENT', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getEVoucherItems (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.ITEMS_E_VOUCHER, payload)
      context.commit('SET_ITEMS', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getRecapEVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.RECAP_E_VOUCHER, payload)
      context.commit('SET_E_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDashboardEVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.DASHBOARD_E_VOUCHER, payload)
      context.commit('SET_E_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  list_e_voucher (state) {
    return state.list_voucher
  },
  e_voucher_glossary (state) {
    return state.e_voucher_glossary
  },
  e_voucher_client (state) {
    return state.e_voucher_client
  },
  e_voucher_items (state) {
    return state.e_voucher_items
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
