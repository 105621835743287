import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_DIGITAL_COUPON, API_USER_MANAGEMENT } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    coupon_setting: { data: [] },
    detail_coupon_setting: { data: [] },
    detail_user: { created_by: [], updated_by: [] },
    data_promo: { data: [] },
    store: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_COUPON_SETTING (state, payload) {
    state.coupon_setting = payload
  },
  SET_DETAIL_COUPON_SETTING (state, payload) {
    state.detail_coupon_setting = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_DATA_PROMO (state, payload) {
    state.data_promo = payload
  },
  SET_STORE (state, payload) {
    state.store = payload
  }
}

const actions = {
  async getListCouponSetting (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.LIST_COUPON_SETTING, payload)
      context.commit('SET_COUPON_SETTING', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailCouponSetting (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.DETAIL_COUPON_SETTING(payload))
      context.commit('SET_DETAIL_COUPON_SETTING', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response_created = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, {ids: [payload?.created_by]})
      const response_updated = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, {ids: [payload?.updated_by]})
      context.commit('SET_DETAIL_USER', {created_by: response_created.data.data, updated_by: response_updated.data.data})
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListPromo(context, payload) {
    try {
      const response = await apiPostAuth(API_DIGITAL_COUPON.GET_PROMO_CODE_COUPON, payload)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createCouponSetting (context, payload) {
    try {
      const response = await apiPostAuth(API_DIGITAL_COUPON.CREATE_COUPON_SETTING, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateCouponSetting (context, payload) {
    try {
      const response = await apiPutAuth(API_DIGITAL_COUPON.UPDATE_COUPON_SETTING(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_coupon_setting (state) {
    return state.coupon_setting
  },
  detail_coupon_setting (state) {
    return state.detail_coupon_setting
  },
  detail_user (state) {
    return state.detail_user
  },
  list_data_promo (state) {
    return state.data_promo
  },
  list_store (state) {
    return state.store
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
