import { notificationDanger } from '@/utils/notification'
import { API_DIGITAL_COUPON } from '@/utils/api-url'
import { apiGetAuth } from '@/utils/api'

const state = () => {
  return {
    download: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_LIST_DOWNLOAD (state, payload) {
    state.download = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListDownload (context, payload) {
    try {
      const response = await apiGetAuth(API_DIGITAL_COUPON.DOWNLOAD_LIST, payload)
      context.commit('SET_LIST_DOWNLOAD', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  }
}

const getters = {
  list_download (state) {
    return state.download
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
