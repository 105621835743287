import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    planogram_impression: {
      isLoading: false,
      data: []
    },
    top_bottom_impression: {
      isLoading: false,
      data: []
    },
    top_bottom_impression_items: {
      isLoading: false,
      data: []
    },
    top_bottom_click: {
      isLoading: false,
      data: []
    },
    top_bottom_click_items: {
      isLoading: false,
      data: []
    },
    top_bottom_cart: {
      isLoading: false,
      data: []
    },
    top_bottom_cart_items: {
      isLoading: false,
      data: []
    },
    top_bottom_sales: {
      isLoading: false,
      data: []
    },
    top_bottom_sales_items: {
      isLoading: false,
      data: []
    },
    tab_id: '0',
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_LIST_PLANOGRAM_IMPRESSION(state, payload) {
    state.planogram_impression = payload
  },
  SET_LIST_TOP_BOTTOM_IMPRESSION(state, payload) {
    state.top_bottom_impression = payload
  },
  SET_LIST_TOP_BOTTOM_IMPRESSION_ITEMS(state, payload) {
    state.top_bottom_impression_items = payload
  },
  SET_LIST_TOP_BOTTOM_CLICK(state, payload) {
    state.top_bottom_click = payload
  },
  SET_LIST_TOP_BOTTOM_CLICK_ITEMS(state, payload) {
    state.top_bottom_click_items = payload
  },
  SET_LIST_TOP_BOTTOM_CART(state, payload) {
    state.top_bottom_cart = payload
  },
  SET_LIST_TOP_BOTTOM_CART_ITEMS(state, payload) {
    state.top_bottom_cart_items = payload
  },
  SET_LIST_TOP_BOTTOM_SALES(state, payload) {
    state.top_bottom_sales = payload
  },
  SET_LIST_TOP_BOTTOM_SALES_ITEMS(state, payload) {
    state.top_bottom_sales_items = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_TAB_ID (state, payload) {
    state.tab_id = payload
  }
}

const actions = {
  async getListPlanogramImpression (context) {
    const DUMMY_DATA = [
      {
        date: "18 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "17 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "16 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "15 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "14 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "13 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      },
      {
        date: "12 juni 2024",
        planogram_impression: "2189584",
        planogram_click: "3189584",
        planogram_cart: "105793",
        planogram_sales_count: "105,793"
      }
    ]
    try {
      context.commit('SET_LIST_PLANOGRAM_IMPRESSION', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_PLANOGRAM_IMPRESSION', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_PLANOGRAM_IMPRESSION', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomImpressionPlanogram (context) {
    const DUMMY_DATA = [
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_impression: "8437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_impression: "3437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_impression: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomImpressionPlanogramItems (context) {
    const DUMMY_DATA = [
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 6",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_impression: "8437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 16",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_impression: "3437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 26",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_impression: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION_ITEMS', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION_ITEMS', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_IMPRESSION_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomClickPlanogram (context) {
    const DUMMY_DATA = [
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_click: "8437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_click: "3437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_click: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_CLICK', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_CLICK', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_CLICK', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomClickPlanogramItems (context) {
    const DUMMY_DATA = [
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 6",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_click: "8437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 16",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_click: "3437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 26",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_click: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_CLICK_ITEMS', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_CLICK_ITEMS', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_CLICK_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomCartPlanogram (context) {
    const DUMMY_DATA = [
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_cart: "8437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_cart: "3437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_cart: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_CART', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_CART', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_CART', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomCartPlanogramItems (context) {
    const DUMMY_DATA = [
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 6",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_cart: "8437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 16",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_cart: "3437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 26",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_cart: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_CART_ITEMS', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_CART_ITEMS', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_CART_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomSalesPlanogram (context) {
    const DUMMY_DATA = [
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_sales_count: "8437062",
        planogram_sales_qty: "3437062",
        planogram_sales_value: "5437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_sales_count: "8437062",
        planogram_sales_qty: "3437062",
        planogram_sales_value: "5437062"
      },
      {
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        planogram_sales_count: "8437062",
        planogram_sales_qty: "3437062",
        planogram_sales_value: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_SALES', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_SALES', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_SALES', {data: [], isLoading: false})
      notificationDanger(error)
    }
  },
  async getListTopBottomSalesPlanogramItems (context) {
    const DUMMY_DATA = [
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 6",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_sales_count: "8437062",
        item_sales_qty: "3437062",
        item_sales_value: "5437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 16",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_sales_count: "3437062",
        item_sales_qty: "3437062",
        item_sales_value: "5437062"
      },
      {
        items: "1903 - Segarnya Hemat 2022 Mar 31 - Apr 26",
        planogram_name: "Planogram Fun May",
        start_date: "31 Mar 2022",
        end_date: "6 Apr 2022",
        item_sales_count: "5437062",
        item_sales_qty: "3437062",
        item_sales_value: "5437062"
      }
    ]
    try {
      context.commit('SET_LIST_TOP_BOTTOM_SALES_ITEMS', {data: [], isLoading: true})
      setTimeout(() => {
        context.commit('SET_LIST_TOP_BOTTOM_SALES_ITEMS', {data: DUMMY_DATA, isLoading: false})
      }, 500)
    } catch (error){
      context.commit('SET_LIST_TOP_BOTTOM_SALES_ITEMS', {data: [], isLoading: false})
      notificationDanger(error)
    }
  }
}

const getters = {
  list_planogram (state) {
    return state.planogram_impression
  },
  list_top_bottom_impression (state) {
    return state.top_bottom_impression
  },
  list_top_bottom_impression_items (state) {
    return state.top_bottom_impression_items
  },
  list_top_bottom_click (state) {
    return state.top_bottom_click
  },
  list_top_bottom_click_items (state) {
    return state.top_bottom_click_items
  },
  list_top_bottom_cart (state) {
    return state.top_bottom_cart
  },
  list_top_bottom_cart_items (state) {
    return state.top_bottom_cart_items
  },
  list_top_bottom_sales (state) {
    return state.top_bottom_sales
  },
  list_top_bottom_sales_items (state) {
    return state.top_bottom_sales_items
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  },
  tab_id (state) {
    return state.tab_id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
