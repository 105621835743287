import { notificationDanger } from '@/utils/notification'
import { API_MASTER_PROMO, API_USER_MANAGEMENT } from '@/utils/api-url'
import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'

const state = () => {
  return {
    master_promo: { data: [] },
    detail_master_promo: { data: [] },
    list_bulk_master_promo : { data: [] },
    detail_user: { data: []},
    status_bulk: '',
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_MASTER_PROMO (state, payload) {
    state.master_promo = payload
  },
  SET_DETAIL_MASTER_PROMO (state, payload) {
    state.detail_master_promo = payload
  },
  SET_LIST_BULK_MASTER_PROMO (state, payload) {
    state.list_bulk_master_promo = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_STATUS_BULK (state, payload) {
    state.status_bulk = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListMasterPromo (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_PROMO.LIST_MASTER_PROMO, payload)
      context.commit('SET_MASTER_PROMO', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailMasterPromo (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_PROMO.DETAIL_MASTER_PROMO(payload))
      context.commit('SET_DETAIL_MASTER_PROMO', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async previewBulkEditMasterPromo (context, payload) {
    try {
      const response = await apiPostAuth(API_MASTER_PROMO.PREVIEW_BULK_MASTER_PROMO, payload)
      context.commit('SET_LIST_BULK_MASTER_PROMO', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async bulkMasterPromo (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_PROMO.BULK_MASTER_PROMO, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, payload)
      context.commit('SET_DETAIL_USER', response.data.data)
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateMasterPromo (context, payload) {
    try {
      const response = await apiPutAuth(API_MASTER_PROMO.UPDATE_MASTER_PROMO(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getStatusBulk (context, payload) {
    try {
      const response = await apiGetAuth(API_MASTER_PROMO.STATUS_BULK, payload)
      context.commit('SET_STATUS_BULK', response.data.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_master_promo (state) {
    return state.master_promo
  },
  detail_master_promo (state) {
    return state.detail_master_promo
  },
  list_bulk_master_promo (state) {
    return state.list_bulk_master_promo
  },
  detail_user (state) {
    return state.detail_user
  },
  status_bulk (state) {
    return state.status_bulk
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
