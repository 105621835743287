import { notificationDanger } from '@/utils/notification'
import { API_USER_MANAGEMENT, API_MAX_PURCHASE } from '@/utils/api-url'
import { apiPostAuth, apiGetAuth, apiPatchAuth, apiDeleteAuth } from '@/utils/api'

const state = () => {
  return {
    max_purchase: { data: [] },
    detail_max_purchase: { data: [] },
    detail_user: { created_by: [], updated_by: [] },
    product: { data: [] },
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_MAX_PURCHASE (state, payload) {
    state.max_purchase = payload
  },
  SET_DETAIL_MAX_PURCHASE (state, payload) {
    state.detail_max_purchase = payload
  },
  SET_DETAIL_USER (state, payload) {
    state.detail_user = payload
  },
  SET_PRODUCT (state, payload) {
    state.product = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListMaxPurchase (context, payload) {
    try {
      const response = await apiGetAuth(API_MAX_PURCHASE.LIST_MAX_PURCHASE, payload)
      context.commit('SET_MAX_PURCHASE', response.data.data)
      context.commit('SET_META_FILTER', payload)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailMaxPurchase (context, payload) {
    try {
      const response = await apiGetAuth(API_MAX_PURCHASE.DETAIL_MAX_PURCHASE(payload))
      context.commit('SET_DETAIL_MAX_PURCHASE', response.data.data)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getListAllDetailUser (context, payload) {
    try {
      const response_created = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, {ids: [payload?.created_by]})
      const response_updated = await apiPostAuth(API_USER_MANAGEMENT.ALL_USERNAME, {ids: [payload?.updated_by]})
      context.commit('SET_DETAIL_USER', {created_by: response_created.data.data, updated_by: response_updated.data.data})
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListProduct (context, payload) {
    try {
      const response = await apiGetAuth(API_MAX_PURCHASE.LIST_PRODUCT, payload)
      context.commit('SET_PRODUCT', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async createMaxPurchase (context, payload) {
    try {
      const response = await apiPostAuth(API_MAX_PURCHASE.CREATE_MAX_PURCHASE, payload)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateMaxPurchase (context, payload) {
    try {
      const response = await apiPatchAuth(API_MAX_PURCHASE.UPDATE_MAX_PURCHASE(payload.id), payload.reqBody)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async deleteMaxPurchase (context, payload) {
    try {
      const response = await apiDeleteAuth(API_MAX_PURCHASE.DELETE_MAX_PURCHASE(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_max_purchase (state) {
    return state.max_purchase
  },
  detail_max_purchase (state) {
    return state.detail_max_purchase
  },
  detail_user (state) {
    return state.detail_user
  },
  list_product (state) {
    return state.product
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
