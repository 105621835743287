<template>
  <div
    id="modal-example"
    :class="`${getToggleModalAddEditDiscard.targetModals ? 'uk-flex-top uk-modal uk-flex uk-open' : 'uk-modal'}`"
    uk-modal
    bg-close="false"
  >
    <div :class="`uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-text-center`">
      <img
        class="uk-margin-small-right"
        :src="`${images}/icon/ic_modal_email.svg`"
        alt="email"
      >
      <h2 class="uk-modal-title">
        {{ getToggleModalAddEditDiscard.title }} 
      </h2>
      <p class="uk-modal-subtitle">
        {{ getToggleModalAddEditDiscard.subtitle }} 
      </p>
      <p class="uk-modal-desc">
        {{ getToggleModalAddEditDiscard.description }}
      </p>
      <p class="uk-text-center uk-flex uk-flex-center uk-width-1-1">
        <button
          v-if="!getToggleModalAddEditDiscard.loadingPopUp"
          :class="`uk-button uk-button-default red uk-flex-center uk-width-1-1 ${getToggleModalAddEditDiscard.customClass}`"
          @click.prevent="handleCancel"
        >
          Okay
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  props: {
    setToggleModalAddEditDiscard: {
      required: false,
      default: null,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  },
  
  methods: {
    handleCancel() {
      this.setToggleModalAddEditDiscard({
        targetModals: false,
        route: '',
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loading: false
      })
    }
  }
}
</script>

<style scoped>
  .uk-modal-body {
    padding: 40px 32px;
    border-radius: 16px;
    width: 408px;
  }
  .uk-modal-title {
    font-family: 'jakartaSemiBold';
    font-weight: 700;
    font-size: 24px;
    color: #000000;
    margin-bottom: 0;
  }
  .uk-modal-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #22252F;
    margin: 8px 0 8px 0;
  }
  .uk-modal-close {
    margin-right: 16px;
  }
  .uk-modal-desc {
    font-family: 'jakartaRegular';
    font-weight: 400;
    font-size: 16px;
    color: #8991A9;
    margin: 0;
  }
</style>
