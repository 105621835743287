import { notificationDanger } from '@/utils/notification'
import { apiGetAuth } from '@/utils/api'
import { API_PANDORA_DASHBOARD_ANALYTIC } from '@/utils/api-url'

const state = () => {
  return {
    monthly_new_registration: { data: [], loading: false },
    monthly_new_open: { data: [], loading: false },
    monthly_active_member: { data: [], loading: false },
    monthly_engaged_member: { data: [], loading: false },
    weekly_new_registration: { data: null, loading: false },
    weekly_new_open: { data: null, loading: false },
    weekly_active_member: { data: null, loading: false },
    weekly_engaged_member: { data: null, loading: false },
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_MONTHLY_NEW_REGISTRATION (state, payload) {
    state.monthly_new_registration = payload
  },
  SET_MONTHLY_NEW_OPEN (state, payload) {
    state.monthly_new_open = payload
  },
  SET_MONTHLY_ACTIVE_MEMBER (state, payload) {
    state.monthly_active_member = payload
  },
  SET_MONTHLY_ENGAGED_MEMBER (state, payload) {
    state.monthly_engaged_member = payload
  },
  SET_WEEKLY_NEW_REGISTRATION (state, payload) {
    state.weekly_new_registration = payload
  },
  SET_WEEKLY_NEW_OPEN (state, payload) {
    state.weekly_new_open = payload
  },
  SET_WEEKLY_ACTIVE_MEMBER (state, payload) {
    state.weekly_active_member = payload
  },
  SET_WEEKLY_ENGAGED_MEMBER (state, payload) {
    state.weekly_engaged_member = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  }
}

const actions = {
  async getMonthlyNewRegistration (context, payload) {
    try {
      context.commit('SET_MONTHLY_NEW_REGISTRATION', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MONTHLY_NEW_REGISTRATION, payload)
      context.commit('SET_MONTHLY_NEW_REGISTRATION', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_MONTHLY_NEW_REGISTRATION', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getMonthlyNewOpen (context, payload) {
    try {
      context.commit('SET_MONTHLY_NEW_OPEN', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MONTHLY_NEW_OPEN, payload)
      context.commit('SET_MONTHLY_NEW_OPEN', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_MONTHLY_NEW_OPEN', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getMonthlyActiveMember (context, payload) {
    try {
      context.commit('SET_MONTHLY_ACTIVE_MEMBER', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MONTHLY_ACTIVE_MEMBER, payload)
      context.commit('SET_MONTHLY_ACTIVE_MEMBER', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_MONTHLY_ACTIVE_MEMBER', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getMonthlyEngagedMember (context, payload) {
    try {
      context.commit('SET_MONTHLY_ENGAGED_MEMBER', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.MONTHLY_ENGAGED_MEMBER, payload)
      context.commit('SET_MONTHLY_ENGAGED_MEMBER', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_MONTHLY_ENGAGED_MEMBER', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getWeeklyNewRegistration (context, payload) {
    try {
      context.commit('SET_WEEKLY_NEW_REGISTRATION', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.WEEKLY_NEW_REGISTRATION, payload)
      context.commit('SET_WEEKLY_NEW_REGISTRATION', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_WEEKLY_NEW_REGISTRATION', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getWeeklyNewOpen (context, payload) {
    try {
      context.commit('SET_WEEKLY_NEW_OPEN', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.WEEKLY_NEW_OPEN, payload)
      context.commit('SET_WEEKLY_NEW_OPEN', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_WEEKLY_NEW_OPEN', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getWeeklyActiveMember (context, payload) {
    try {
      context.commit('SET_WEEKLY_ACTIVE_MEMBER', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.WEEKLY_ACTIVE_MEMBER, payload)
      context.commit('SET_WEEKLY_ACTIVE_MEMBER', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_WEEKLY_ACTIVE_MEMBER', {data: [], loading: false})
      notificationDanger(error)
    }
  },
  async getWeeklyEngagedMember (context, payload) {
    try {
      context.commit('SET_WEEKLY_ENGAGED_MEMBER', {data: [], loading: true})
      const { data } = await apiGetAuth(API_PANDORA_DASHBOARD_ANALYTIC.WEEKLY_ENGAGED_MEMBER, payload)
      context.commit('SET_WEEKLY_ENGAGED_MEMBER', {data: data.data, loading: false})
    } catch (error){
      context.commit('SET_WEEKLY_ENGAGED_MEMBER', {data: [], loading: false})
      notificationDanger(error)
    }
  }
}

const getters = {
  monthly_new_registration (state) {
    return state.monthly_new_registration
  },
  monthly_new_open (state) {
    return state.monthly_new_open
  },
  monthly_active_member (state) {
    return state.monthly_active_member
  },
  monthly_engaged_member (state) {
    return state.monthly_engaged_member
  },
  weekly_new_registration (state) {
    return state.weekly_new_registration
  },
  weekly_new_open (state) {
    return state.weekly_new_open
  },
  weekly_active_member (state) {
    return state.weekly_active_member
  },
  weekly_engaged_member (state) {
    return state.weekly_engaged_member
  },
  meta_filter (state) {
    return state.meta_filter
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
