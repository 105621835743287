import { apiGetAuth } from '@/utils/api'
import { API_STORE, API_SALES_DASHBOARD } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    store: { data: [] },
    supervisor: { data: [] },
    data_modal_add_edit: {
      preview: false
    },
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false,
      loadingComponent: false
    },
    toggle_modal_share: {
      targetModals: false,
      title: '',
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_SUPERVISOR (state, payload) {
    state.supervisor = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload) {
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_TOGGLE_MODAL_SHARE(state, payload) {
    state.toggle_modal_share = payload
  }, 
  SET_META_FILTER(state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
      return response.data
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListSupervisor (context, payload) {
    try {
      const response = await apiGetAuth(API_SALES_DASHBOARD.LIST_SUPERVISOR, payload)
      context.commit('SET_SUPERVISOR', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  list_store (state) {
    return state.store
  },
  list_supervisor (state) {
    return state.supervisor
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_toggle_modal_share (state) {
    return state.toggle_modal_share
  },
  get_meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
