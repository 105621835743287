import UIkit from 'uikit'

const timeout = 3000
const typeNotif = {
  1: {
    text: 'success',
    icon: 'ic_check_circle.svg'
  },
  2: {
    text: 'danger',
    icon: 'warning'
  },
  3: {
    text: 'primary',
    icon: 'info'
  }
}
const createMessage = (err) => {
  let data = null
  if (err.response) {
    const message = err.response
    data = typeof message.data === 'object' ? message.data.error || message.data.message : message.data
  } else {
    data = err.message
  }
  
  return data || err || 'Something went wrong'
}

const notificationSuccess = (message, images) => {
  UIkit.notification({
    message: `<div style='text-align: center'><img src="${images || '/images'}/icon/${typeNotif[1].icon}" /> ${message || 'Success'}<div/>`,
    status: typeNotif[1].text,
    timeout
  })
}

const notificationDanger = (message) => {
  UIkit.notification({
    message: `<span uk-icon='icon: ${typeNotif[2].icon}'></span> ${createMessage(message)}`,
    status: typeNotif[2].text,
    timeout
  })
}

const notificationInfo = (message) => {
  UIkit.notification({
    message: `<span uk-icon='icon: ${typeNotif[3].icon}'></span> ${message}`,
    status: typeNotif[3].text,
    timeout
  })
}

export {
  notificationSuccess,
  notificationDanger,
  notificationInfo
}
